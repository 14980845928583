<template>
  <div class="animation-container" v-intersect.once="entries => onIntersect(entries, 'show')">
    <v-scale-transition>
      <v-card
        v-if="show"
        :class="
          'pt-4 pt-sm-8 pb-2 pb-sm-3 py-md-8 px-3 pricing-card mx-auto ' + cardClass
        "
        outlined
      >
        <v-card-text class="pa-0">
          <h3 class="title-text">{{ title }}</h3>
          <h3 class="dimension-text">{{ dimensions }}</h3>
          <h3 class="price-text">${{ price }}</h3>
          <h4 class="price-per-text">{{ pricePer }}</h4>
        </v-card-text>
      </v-card>
    </v-scale-transition>
  </div>
</template>

<script>
import AnimationTriggerMixin from '@/mixins/AnimationTriggerMixin';

export default {
  name: "PricingCard",
  mixins: [AnimationTriggerMixin],
  props: {
    title: {
      type: String,
      required: true,
    },
    dimensions: {
      type: String,
      required: true,
    },
    price: {
      type: String,
      required: true,
    },
    pricePer: {
      type: String,
      required: true,
    },
    cardClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    colorClass() {
      return `var(--v-${this.color}-base)`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/styles.sass";

.animation-container {
  min-height: 200px;
}

.pricing-card {
  text-align: center;
  border: 2px solid var(--v-primary-base);
  border-radius: 10px;
  width: 640px;
  max-width: 100%;
}

.pricing-card.variant {
  border-color: var(--v-text-base);
}

.title-text {
  color: var(--v-primary-base);
  font-size: 72px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 3.6px;
  text-transform: uppercase;
  margin-bottom: 52px;
}

.dimension-text {
  color: var(--v-secondaryText-base);
  font-size: 72px;
  font-weight: 350;
  line-height: normal;
  margin-bottom: 52px;
}

.price-text {
  color: var(--v-primaryLight-base);
  font-size: 72px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 16px;
}

.price-per-text {
  color: var(--v-primaryLight-base);
  text-align: center;
  font-size: 36px;
  font-weight: 350;
  line-height: normal;
}

.variant .title-text,
.variant .price-text {
  color: var(--v-text-base);
}

.variant .price-per-text {
  color: var(--v-secondary-base);
}

.questions-price-card {
  max-width: 100%;
  width: 100%;

  .title-text,
  .dimension-text,
  .price-text,
  .price-per-text {
    color: var(--v-textOnSecondary-base);
  }

  .title-text,
  .dimension-text {
    margin-bottom: 24px;
  }

  .title-text,
  .dimension-text,
  .price-text {
    font-size: 48px;
  }

  .title-text {
    letter-spacing: 2.4px;
  }

  .dimension-text {
    font-weight: 400;
  }

  .price-text {
    margin-bottom: 14px;
  }

  .price-per-text {
    font-size: 24px;
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .pricing-card {
    width: 280px;
  }

  .title-text {
    font-size: 48px;
  }

  .dimension-text {
    font-size: 48px;
  }

  .price-text {
    font-size: 64px;
  }

  .price-per-text {
    font-size: 28px;
  }

  .questions-price-card {
    max-width: 100%;
    width: 100%;
  }
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .pricing-card {
    width: 151px;
  }

  .title-text {
    font-size: 28px;
    line-height: normal;
    letter-spacing: 1.4px;
    margin-bottom: 16px;
  }

  .dimension-text {
    font-size: 28px;
    margin-bottom: 16px;
  }

  .price-text {
    font-size: 36px;
    margin-bottom: 0px;
  }

  .variant .price-per-text {
    color: var(--v-text-base);
  }

  .price-per-text {
    font-size: 16px;
  }

  .questions-price-card {
    .title-text,
    .dimension-text {
      margin-bottom: 18px;
    }

    .title-text,
    .dimension-text,
    .price-text {
      font-size: 18px;
    }

    .price-text {
      font-size: 24px;
      margin-bottom: 0px;
    }

    .price-per-text {
      font-size: 16px;
    }
  }
}
</style>
