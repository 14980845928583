<template>
  <v-app>
    <AppBar/>
    <v-main>
      <v-fade-transition mode="out-in">
        <router-view v-if="!loading"/>
      </v-fade-transition>
    </v-main>
    <FooterSkirt/>
    <Footer/>
  </v-app>
</template>

<script>
import AppBar from './components/AppBar';
import Footer from './components/Footer';
import FooterSkirt from './components/FooterSkirt';
import "@/assets/styles/mystyles.css";
import Http from '@/api/Http';
import { mutationNames, getterNames } from '@/store/global';

export default {
  name: 'App',

  components: {
    AppBar,
    Footer,
    FooterSkirt,
  },
  async created() {
    this.auth();
  },
  data: () => ({
    api: Http
        .use('data')
  }),
  computed: {
    loading: function () {
      return this.$store.getters[getterNames.loading];
    },
  },
  methods: {
    auth() {
      this.$store.commit(mutationNames.LOADING, true);
      this.api.get('/auth/check')
          .then(res => {
            if (res.profile) {
              this.$store.commit(mutationNames.UPDATE_CURRENT_USER, res.profile);
              this.$store.commit(mutationNames.SET_STRIPE_KEY, res.stripe_key);
              this.$store.commit(mutationNames.LOADING, false);
              const redirect = this.$store.getters[getterNames.unauthPath];
              if (redirect && this.$route.path !== redirect) {
                this.$router.push(redirect);
              }
            } else {
              this.$store.commit(mutationNames.UPDATE_CURRENT_USER, false);
            }
          })
          .catch(() => {
            this.$store.commit(mutationNames.LOADING, false);
          })
    }
  }
};
</script>

<style>
  @font-face {
    font-family: "PhosphatePro";
    src: local("PhotphatePro"),
    url(./assets/fonts/PhosphatePro-Inline.otf) format("truetype");
  }
  @font-face {
    font-family: "BebasNeue";
    src: local("BebasNeueProRegular"),
    url(./assets/fonts/BebasNeue/BebasNeueProRegular.otf) format("truetype");
    font-weight: normal;
  }
  @font-face {
    font-family: "BebasNeue";
    src: local("BebasNeueProBold"),
    url(./assets/fonts/BebasNeue/BebasNeueProBold.otf) format("truetype");
    font-weight: bold;
  }
  @font-face {
    font-family: "BebasNeue";
    src: local("BebasNeueProLight"),
    url(./assets/fonts/BebasNeue/BebasNeueProLight.otf) format("truetype");
    font-weight: 300;
  }
</style>
