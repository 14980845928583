<template>
  <v-container v-intersect.once="onIntersect" fluid>
    <v-row class="mb-10">
      <transition name="slide">
        <div v-if="show" class="d-flex align-end arm-container">
          <div id="arm" class="mr-2"></div>
          <img :src="homeHand"/>
        </div>
      </transition>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'HomeArm',
  data() {
    return {
      show: false
    }
  },
  computed: {
    homeHand() {
      return require('@/assets/Home_Hand.svg')
    }
  },
  methods: {
    onIntersect (entries) {
      this.show = entries[0].isIntersecting;
    },
  }
}
</script>

<style scoped>
  #arm {
    width: 75%;
    height: 4rem;
    background: #F0A7B8;
    margin-bottom: 9px;
  }
  .arm-container {
    width: 100%;
  }
 
  .slide-enter-active {
    animation: slideRight 2s;
  }

  @media only screen and (min-width: 601px) and (max-width: 1264px) {
    #arm {
      height: 2.2rem;
      margin-bottom: 5px;
    }
    img {
      width: 10rem;
      height: auto;
    }
  }
  @media only screen and (max-width: 600px) {
    #arm {
      height: 1.6rem;
      margin-bottom: 4px;
    }
    img {
      width: 7.5rem;
      height: auto;
    }
  }
</style>