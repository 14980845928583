<template>
  <v-container>
    <h2 class="header header-span mb-8">{{ $t('prompts.header') }}</h2>
    <v-card max-width="700" class="mx-auto" flat>
      <v-card-text class="text-h4 pb-9">
        "But what do I SAY?" No worries--we'll help you.
        We'll text you questions to prompt you, we just need to know how often you'd like to hear from us!
      </v-card-text>
      <v-card-text class="section-header">
        FREQUENCY?
      </v-card-text>
      <v-card-text>
        <v-row class="mb-9">
          <v-col cols="8" class="mx-auto">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="primaryAction"
                    block
                    v-bind="attrs"
                    v-on="on"
                    height="100"
                    rounded
                >
                  <span class="menu-btn">{{ $t(items[form.freq]) }}</span>
                </v-btn>
              </template>
              <v-list>
                <v-list-item-group
                    v-model="form.freq"
                >
                  <v-list-item
                      v-for="(item, index) in items"
                      :key="index"
                      link
                  >
                    <v-list-item-title>{{ $t(item) }}</v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text class="section-header">TIME ZONE?</v-card-text>
      <v-card-text>
        <v-row>
          <v-col cols="8" class="mx-auto">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="primaryAction"
                    block
                    v-bind="attrs"
                    v-on="on"
                    height="100"
                    rounded
                >
                  <span class="menu-btn">{{ $t(tz[form.tz]) }}</span>
                </v-btn>
              </template>
              <v-list>
                <v-list-item-group
                    v-model="form.tz"
                >
                  <v-list-item
                      v-for="(item) in Object.keys(tz)"
                      :key="item"
                      :value="item"
                      link
                  >
                    <v-list-item-title>{{ $t(tz[item]) }}</v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
        <AlertError :error="error" />
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
            class="btn btn--accent mb-5"
            color="text"
            block
            @click="submit">
            <span class="btn__text btn__text--white">
              {{ $t('createJamPage.go') }}
            </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import AlertError from '@/components/AlertError';
import { frequency, timeZones } from '@/lib/';

  export default {
    name: 'JamPrompts',
    components: {
      AlertError
    },
    props: {
      jam: {
        type: Object,
        default: () => ({ freq: 0, selTz: 'MST', tod: 1 })
      }
    },
    data () {
      return {
        form: {...this.jam},
        error: '',
        freq: 0,
        selTz: 'MST',
        tod: 1,
        items: frequency,
        tz: timeZones,
      }
    },
    methods: {
      submit () {
        // console.log(this.form);
        this.$emit('save', this.form);
        // let cpy = Object.assign({
        //   timeZone: this.selTz,
        //   frequency: this.freq,
        //   timeOfDay: this.tod
        // }, this.selected);
        // this.$store.commit('jam/UPDATE', cpy);
        // this.$router.push('/pay/' + this.$route.params.id);
      }
    },
    watch: {
      jam: function(newVal) {
        this.form = {...newVal};
      }
    }
  }
</script>

<style scoped>
.header {
  /*color: var(--v-secondary-base);*/
  text-align: center;
  line-height: 100%;
}

.section-header {
  text-align: center;
  font-size: 62px;
  font-weight: 900;
}

.menu-btn {
  text-align: center;
  font-weight: bold;
  font-size: 50px;
  color: #FFFFFF;
}
</style>
