<template>
  <v-container>
    <v-row>
      <v-col class="recipt pa-6">
        <p class="recipt-line">{{ typeTag }}</p>
        <p class="recipt-line">{{ dates }}</p>
        <p class="recipt-line">{{ propts }}</p>
        <p class="recipt-line">{{ $t('pay.jammers') }}</p>
        <h2 class="recipt-confirm">{{ $t('pay.good') }}</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p>
          {{ $t('pay.afterPay') }}
          <span class="font-weight-bold">
            {{ $t('pay.exclusive') }}
          </span>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'PayCart',
    props: {
      type: {
        type: String,
        default: null
      },
      start: {
        type: String,
        default: null
      },
      end: {
        type: String,
        default: null
      },
      freq: {
        type: String
      },
      typeName: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        typeDef: 'Travel Journal'
      }
    },
    computed: {
      typeTag() {
        return `${this.$t('pay.type')} ${this.typeName} jam`;
      },
      dates() {
        return `${this.$t('pay.from')} ${this.start} ${this.$t('pay.to')} ${this.end}`;
      },
      propts() {
        return `${this.$t('pay.propts')} ${this.freq}`;
      }
    }
  }
</script>

<style scoped>
.recipt {
  background-color: var(--v-primary-base);
}
.recipt-line {
  color: #ffffff;
  font-size: 2.5rem;
  font-weight: bold;
  text-transform: uppercase;
}
.recipt-confirm {
  color: #000000;
  font-weight: bold;
}

</style>
