<template>
  <v-container>
    <v-row class="d-flex flex-column">
      <v-col>
        <v-card class="card" color="primary">
          <v-row
              @click="$emit('menuClick', 'prompts')"
              style="cursor: pointer;"
          >
            <v-col>
              <img class="phone__img" :src="phone" />
              <div
                  class="card__text-wrapper--left"
              >
                <v-card-title class="justify-end card__title card__title--white">
                  {{ $t('accountPage.prompts') }}
                </v-card-title>
                <v-card-subtitle v-if="showDescription" class="text-right card__subtitle card__text--white ma-1">
                  {{ $t('accountPage.editPrompts') }}
                </v-card-subtitle>
              </div>
            </v-col>
          </v-row>
        </v-card>
        <v-card class="card" color="secondary">
          <v-row
              @click="$emit('menuClick', 'jammers')"
              style="cursor: pointer;"
          >
            <v-col>
              <img class="jammers__img" :src="jammers" />
              <div class="card__text-wrapper--right">
                <v-card-title class="card__title card__title--white">
                  {{ $t('accountPage.jammers') }}
                </v-card-title>
                <v-card-subtitle v-if="showDescription" class="card__subtitle card__text--white ma-1">
                  {{ $t('accountPage.addRemoveCollaborators') }}
                </v-card-subtitle>
              </div>
            </v-col>
          </v-row>
        </v-card>
        <v-card class="card" color="secondaryBackground">
          <v-row
              @click="$emit('menuClick', 'layout')"
              style="cursor: pointer;"
          >
            <v-col>
              <div class="card__text-wrapper--open-book">
                <v-card-title class="card__title card__title--white">
                  {{$t('accountPage.bookLayout')}}
                </v-card-title>
                <v-card-subtitle v-if="showDescription" class="card__subtitle card__text--white ma-1">
                  {{ $t('accountPage.fixTypos') }}
                </v-card-subtitle>
              </div>
              <img class="open-book__img" :src="openBook" />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'InfoCards',
    computed: {
      fruit() {
        return require('@/assets/FRUIT.svg')
      },
      phone() {
        return require('@/assets/PHONE.svg')
      },
      jammers() {
        return require('@/assets/JAMMERS.svg')
      },
      jambook() {
        return require('@/assets/JAMBOOKS_BOOK.svg')
      },
      openBook() {
        return require('@/assets/OPEN_BOOK.svg')
      },
      showDescription () {
        return !(this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm');
      },
      currentUser: function () {
        return this.$store.state.user;
      },
    }
  }
</script>

<style scoped>
  .card {
    border-radius: 20px;
    height: 12rem;
    margin-bottom: 3.75rem;
  }
  .card__title {
    font-size: 3rem;
    letter-spacing: 0.1em;
    line-height: 115%;
  }
  .card__subtitle {
    font-size: 1.6rem;
    letter-spacing: 0.1em;
  }
  .fruit__img {
    position: absolute;
    top: 3rem;
  }
  .phone__img {
    position: absolute;
    top: 3rem;
    margin-right: 1.25rem;
  }
  .jammers__img {
    position: absolute;
    top: 4rem;
    right: 0;
    margin-left: 1.25rem;
  }
  .open-book__img {
    position: absolute;
    top: 1.25rem;
  }
  .jambook__img {
    position: absolute;
    top: 3rem;
    right: 0;
    margin-right: 1.25rem;
  }
  .card__text-wrapper--left {
    margin-left: 12rem;
  }
  .card__text-wrapper--right {
    margin-right: 12rem;
  }
  .card__text-wrapper--open-book {
    margin-left: 15rem;
  }

  @media only screen and (max-width: 600px) {
    .card__title {
      font-size: 2rem;
    }
    .open-book__img {
      height: 90%;
      top: 3rem;
    }
    .jambook__img {
      top: 3.8rem;
    }
    .card__text-wrapper--right {
      margin-right: 5rem;
    }
    .card__text-wrapper--left {
      margin-left: 6rem;
    }
    .card__text-wrapper--open-book {
      margin-left: 10rem;
    }
  }
</style>
