<template>
  <v-container fluid class="content-container">
    <v-container fluid v-intersect.once="entries => onIntersect(entries, 'show')">
      <img class="bg-image" alt="books background" src="../assets/books_background_cropped.png"/>
      <v-slide-x-transition>
        <div v-if="show" class="content">
          <h2 class="header__title">
            {{ $t('homePage.booksBanner.title.line1') }}<br/>
            {{ $t('homePage.booksBanner.title.line2') }}<br/>
            {{ $t('homePage.booksBanner.title.line3') }}<br/>
            {{ $t('homePage.booksBanner.title.line4') }}
          </h2>
          <h4 class="header__subtitle">
            {{ $t('homePage.booksBanner.subtitle.line1') }}<br/>
            {{ $t('homePage.booksBanner.subtitle.line2') }}<br/>
            {{ $t('homePage.booksBanner.subtitle.line3') }}
          </h4>
          <DefaultButton
            class="pattern2_btn"
            color="white"
            :block="$vuetify.breakpoint.xs"
            :to="{ path: '/products' }"
          >
            {{ $t('homePage.booksBanner.buttonText') }}
          </DefaultButton>
        </div>
      </v-slide-x-transition>
    </v-container>
  </v-container>
</template>

<script>
import DefaultButton from '@/components/buttons/DefaultButton.vue';
import AnimationTriggerMixin from '@/mixins/AnimationTriggerMixin';

export default {
  name: 'HomeBooksBanner',
  mixins: [AnimationTriggerMixin],
  components: {
    DefaultButton,
  },
  data() {
    return {
      show: false,
    };
  },
}
</script>

<style lang="scss" scoped>

@import '@/styles/forms.scss';

.content-container {
  margin: 80px 0;
  position: relative;
  background-color: #b08157;
  background-image: linear-gradient(0deg, rgba(178,133,93,1) 0%, rgba(175,129,88,1) 50%, rgba(159,113,73,1) 100%);
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 40px 80px;
}

.bg-image {
  position: absolute;
  top: 0;
  right: 0;
  width: auto;
  height: 100%;
  mask-image: linear-gradient(90deg, rgba(0,0,0,0) 10%, rgba(0,0,0,1) 35%);
  user-select: none;
}

.content {
  position: relative;
  max-width: 1185px;
  margin: auto;
  text-align: left;
}

.header__title {
  font-size: 56px;
  font-family: 'EB Garamond', serif;
  color: var(--v-textOnSecondary-base);
  margin-bottom: 50px;
  line-height: 120%;
  text-transform: lowercase;
}

.header__subtitle {
  font-size: 36px;
  font-weight: 400;
  color: var(--v-secondaryText-base);
  line-height: 150%;
  margin-bottom: 30px;
  text-transform: none;
}

.pattern2_btn {
  width: 350px;
  max-width: 100%;
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .bg-image {
    opacity: 0.3;
  }
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .content-container {
    padding: 20px 30px;
  }

  .header__title {
    font-size: 36px;
  }

  .header__subtitle {
    font-size: 24px;
  }
}
</style>