<template>
  <v-container class="px-0" fluid>
    <v-row class="d-flex align-center justify-center header">
      <v-col cols="auto" class="">
        <h1 class="header__title">{{ $t('accountPage.jambossAccount') }}</h1>
      </v-col>
    </v-row>
    <v-container v-intersect.once="onIntersect" class="px-5" fluid>
      <v-row>
        <v-col class="left-container" lg="4" cols="4">
          <AccountMenu
              class="d-none d-lg-block"
              @menuClick="handleClick"
          />
        </v-col>
        <v-col class="px-0" lg="8" md="12" cols="12">
          <router-view
              @notify="handleNotification"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
        color="success"
        elevation="24"
        min-width="none"
        height="50"
        v-model="notify.visible">
      <div class="snack-text">
        <v-icon large class="pr-3" v-if="notify.icon">{{  notify.icon }}</v-icon>
        {{ notify.text }}
      </div>
    </v-snackbar>
  </v-container>
</template>

<script>
import AccountMenu from '../components/account/AccountMenu';

  export default {
    name: 'Account',
    components: {
      AccountMenu,
    },
    data() {
      return {
        showBannerText: false,
        jams: [
          'Jam Book 1',
          'Jam Book 2',
          'Jam Book 3',
          'Jam Book 4',
          'Jam Book 5',
          'Jam Book 6',
          'Jam Book 7',
          'Jam Book 8'
        ],
        notify: {
          visible: false,
          icon: null,
          text: null,
          color: null
        }
      }
    },
    methods: {
      async getData() {
        this.axios.get('/api/auth/check')
          .then(response => {
            console.log(response);
          })
        console.log('getData()called');
      },
      onIntersect(entries) {
        let vm = this
          setTimeout(function() {
            vm.showBannerText = entries[0].isIntersecting;
          }, 500);
      },
      handleClick(to) {
        if (this.$route.path !== '/account/' + to) {
          this.$router.push('/account/' + to);
        }
      },
      handleNotification(options) {
        this.notify = options;
      }
    }
  }
</script>

<style scoped>
  .animation-min-height {
    min-height: 2rem;
  }
  .v-tab--active.tab__text:after {
    content: "";
    background: var(--v-text-base);
    border-radius: 1rem;
    position: absolute;
    bottom: -2px;
    width: 50%;
    height: 4px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .header {
    margin-bottom: 1rem;
  }
  .header__title {
    font-size: 5rem;
    color: var(--v-primary-base);
  }
  .banner >>> .v-banner__content {
    padding-top: .5rem;
  }
  .banner {
    text-align: center;
  }
  .banner__text {
    font-size: 2.75rem;
    color: white;
    font-weight: bold;
  }
  .tab__text {
    font-size: 2.5rem;
    font-weight: bold;
    letter-spacing: 0;
    margin-right: 6.25rem;
    padding-bottom: 10px;
  }
  .left-container {
    /*margin-right: 2rem;*/
  }
  .theme--light.v-tabs >>> .v-tab:not(.v-tab--active) {
    font-weight: 400;
    color: var(--v-text-base);
  }

  @media only screen and (max-width: 1264px) {
    .header {
      margin-top: 0;
      margin-bottom: 0;
    }
    .banner__text {
      font-size: 1.5rem;
    }
    .banner >>> .v-banner__wrapper {
      padding: .5rem
    }
    .tab__text {
      font-size: 1.5rem;
      margin-right: 3.5rem;
    }
  }
  @media only screen and (min-width: 601px) and (max-width: 1264px) {
    .header__title {
      font-size: 4.6rem;
    }
  }
  @media only screen and (max-width: 600px) {
    .header__title {
      font-size: 2rem;
    }
  }
  .snack-text {
    font-size: 1.6rem;
    text-align: center;
    display: block;
    /*margin: 10px;*/
    margin-top: 3px;
    color: #ffffff;
  }
  .v-snack__wrapper {
    max-width: none;
  }
</style>
