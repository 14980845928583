<template>
  <v-container>
    <h2 class="header header-span mb-8" color="secondaryBackground">{{ $t('login.welcome') }}</h2>
    <v-row class="d-flex justify-center">
      <v-col>
        <v-form
            @submit.prevent="submit"
            v-model="valid"
            lazy-validation
            ref="form">
          <AlertError :error="errorMsg" />
          <TextField
              class="mb-lg-10 mb-5"
              :placeholder="$t('login.phone')"
              :label="$t('login.phone')"
              v-model="username"
              ref="username"
              autofocus="autofocus"
              autocomplete="username"
              type="text"
              name="username"
              :rules="[rules.required, rules.username ]"
              @change="$emit('change')"
          />
          <TextField

              type="password"
              ref="pass"
              autocomplete="current-password"
              :placeholder="$t('login.password')"
              :label="$t('login.password')"
              v-model="pass"
              :rules="[ rules.required ]"
              @change="$emit('change')"
          />
          <div class="forgot mb-lg-5">
            <router-link to="/forgot">Forgot your password?</router-link>
          </div>
          <v-btn
              block
              class="btn btn--accent mb-5"
              color="secondary"
              type="submit"
              :loading="loading"
          >
            <span slot="loader">
              <v-progress-circular
                  indeterminate
                  color="primary"
              />
            </span>
              <span class="btn__text btn__text--white">
                {{ $t('login.loginBtn') }}
              </span>
          </v-btn>
        </v-form>
        <div class="account">
          {{ $t('login.noLogin') }}
          <router-link to="/signup">{{ $t('login.signUp') }}</router-link>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TextField from '@/components/TextField';
import AlertError from '@/components/AlertError';

import { validatePhone } from '@/lib/';

  export default {
    name: 'LoginForm',
    props: {
      errorMsg: {
        type: String
      },
      loading: {
        type: Boolean,
        default: false
      }
    },
    components: {
      TextField,
      AlertError
    },
    data() {
      return {
        valid: true,
        errors: [],
        username: null,
        pass: null,
        rules: {
          required: value => !!value || this.$t('login.required'),
          username: value => validatePhone(value) || this.$t('login.badPhone')
        },
        formHasErrors: false
      }
    },
    methods: {
      submit: function() {
        if (this.$refs.form.validate()) {
          this.$emit('auth', this.form);
        }
      }
    },
    computed: {
      form () {
        return {
          username: this.username,
          pass: this.pass
        }
      }
    }
  }
</script>

<style scoped>
.header {
  color: var(--v-secondary-base);
  text-align: center;
  line-height: 100%;
}
.account {
  color: var(--v-secondary-background-emphasis-base);
  text-align: center;
  line-height: 100%;
  font-size: 1.9rem;
  font-weight: bold;
}
.error {
  text-align: center;
  line-height: 150%;
  font-size: 1.9rem;
  font-weight: bold;
  color: #FFFFFF;
}
.forgot {
  line-height: 150%;
  font-size: 1.5rem;
}
</style>
