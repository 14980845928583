<template>
  <v-container class="text-container-wrapper">
    <v-row class="d=flex justify-center">
      <v-col class="d-flex flex-column" lg="5" cols="12">
        <div class="header-text-container mb-5">
          <v-slide-x-transition>
            <h5 v-show="showlifeMovesFast" class="fancy-font">{{ $t('homePage.lifeMovesFast') }}</h5>
          </v-slide-x-transition>
        </div>
        <div v-intersect.once="onIntersectStopAndLook" class="left-container">
          <v-slide-x-transition>
            <p v-show="showLookAround">{{ $t('homePage.stopAndLook') }}</p>
          </v-slide-x-transition>
        </div>
        <div v-intersect.once="onIntersectDoesntTakeHours" class="left-container">
          <v-slide-x-transition>
            <p v-show="showDoesntTakeHours" v-html="$t('homePage.doesntTakeHours')"></p>
          </v-slide-x-transition>
          <v-slide-x-transition>
            <p v-show="showDoesntTakeHours" v-html="$t('homePage.noClunkyApps')"></p>
          </v-slide-x-transition>
          <v-slide-x-transition>
            <p v-show="showDoesntTakeHours" v-html="$t('homePage.cleverCaptions')"></p>
          </v-slide-x-transition>
          <v-slide-x-transition>
            <p v-show="showDoesntTakeHours" v-html="$t('homePage.noSocialMedia')"></p>
          </v-slide-x-transition>
        </div>
        <div v-intersect.once="onIntersectWeCustomize" class="left-container">
          <v-slide-x-transition>
            <p v-show="showWeCustomize" class="text--bold">{{ $t('homePage.customize') }}</p>
          </v-slide-x-transition>
          <v-slide-x-transition>
            <p v-show="showWeCustomize" class="text--bold" >{{ $t('homePage.send') }}</p>
          </v-slide-x-transition>
          <v-slide-x-transition>
            <p v-show="showWeCustomize" class="text--bold">{{ $t('homePage.build') }}</p>
          </v-slide-x-transition>
        </div>
        <div v-intersect.once="onIntersectMakesYourMemories" class="left-container">
          <v-slide-x-transition>
            <p v-show="showMakesYourMemories" v-html="$t('homePage.memoriesStick')"></p>
          </v-slide-x-transition>
          <v-slide-x-transition>
            <p v-show="showMakesYourMemories" class="text--bold" >{{ $t('homePage.intentional') }}</p>
          </v-slide-x-transition>
          <v-slide-x-transition>
            <p v-show="showMakesYourMemories" v-html="$t('homePage.connects')"></p>
          </v-slide-x-transition>
        </div>
        <div v-intersect.once="onIntersectEnd"></div>
      </v-col>
      <v-col class="d-flex flex-column" lg="6" cols="12">
        <div v-intersect.once="onIntersectRight" class="d-flex img-container mb-lg-6 mb-13">
          <v-slide-x-reverse-transition>
            <img v-show="showLogo" :src="jambookCircleLogo"/>
          </v-slide-x-reverse-transition>
        </div>
        <v-slide-x-reverse-transition>
          <span v-show="showCreate" class="fancy-font text__right">
            {{ $t('homePage.createExperiences') }}
          </span>
        </v-slide-x-reverse-transition>
        <v-slide-x-reverse-transition>
          <span v-show="showConnect" class="fancy-font text__right">
            {{ $t('homePage.connect') }}
          </span>
        </v-slide-x-reverse-transition>
        <v-slide-x-reverse-transition>
          <span v-show="showBestPhotoBooks" class="fancy-font text__right">
          {{ $t('homePage.bestPhotobooks') }}
          </span>
        </v-slide-x-reverse-transition>
      </v-col>
    </v-row>
    <v-row v-intersect.once="onIntersectButton" class="d-flex justify-center">
      <v-col lg="6" cols="10">
        <v-scroll-y-reverse-transition>
          <v-btn v-show="showButton" class="btn btn--pattern" block depressed @click="$router.push('/signup')">
            <span class="btn__text btn__text--white">
              {{ $t('homePage.startPreserving') }}
            </span>
          </v-btn>
        </v-scroll-y-reverse-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'LifeMovesFast',
    data() {
      return {
        showlifeMovesFast: false,
        showLookAround: false,
        showDoesntTakeHours: false,
        showWeCustomize: false,
        showMakesYourMemories: false,
        showLogo: false,
        showCreate: false,
        showConnect: false,
        showBestPhotoBooks: false,
        showButton: false,
      }
    },
    computed: {
      jambookCircleLogo() {
        return require('@/assets/Jambook_Logo_Circle.svg')
      }
    },
    methods: {
      onIntersectStopAndLook (entries) {
        let vm = this
        setTimeout(function() {
          vm.showlifeMovesFast = entries[0].isIntersecting;
        }, 500);
      },
      onIntersectDoesntTakeHours (entries) {
        let vm = this
        setTimeout(function() {
          vm.showLookAround = entries[0].isIntersecting;
        }, 550);
      },
      onIntersectWeCustomize(entries) {
        let vm = this
        setTimeout(function() {
          vm.showDoesntTakeHours = entries[0].isIntersecting;
        }, 600);
      },
      onIntersectMakesYourMemories (entries) {
        let vm = this
        setTimeout(function() {
          vm.showWeCustomize = entries[0].isIntersecting;
        }, 650);
      },
      onIntersectEnd (entries) {
        let vm = this
        setTimeout(function() {
          vm.showMakesYourMemories = entries[0].isIntersecting;
        }, 650);
      },
      onIntersectRight (entries) {
        let vm = this
        setTimeout(function() {
          vm.showLogo = entries[0].isIntersecting;
        }, 650);
        setTimeout(function() {
          vm.showCreate = entries[0].isIntersecting;
        }, 700);
        setTimeout(function() {
          vm.showConnect = entries[0].isIntersecting;
        }, 750);
        setTimeout(function() {
          vm.showBestPhotoBooks = entries[0].isIntersecting;
        }, 800);
      },
      onIntersectButton (entries) {
        let vm = this
        setTimeout(function() {
          vm.showButton = entries[0].isIntersecting;
        }, 800);
      },
    }
  }
</script>

<style scoped>
  .text-container-wrapper {
    margin-bottom: 6.25rem;
  }
  .header-text-container {
    text-align: right;
  }
  .left-container {
    margin-bottom: 4rem;
    text-align: right;
  }
  .text__right {
    font-size: 3rem;
    margin-bottom: 6.5rem;
    line-height: 100%;
  }
  .text--bold {
    font-weight: bold;
  }
  .btn {
    background: url(../assets/Start_Preserving_Bg.svg);
    background-size: cover;
  }
  p {
    margin-bottom: 0;
  }
  @media only screen and (max-width: 1264px) {
    .text-container-wrapper {
      margin-bottom: 3.75rem;
    }
    .left-container {
      text-align: center;
      line-height: 210%
    }
    .header-text-container {
      text-align: center;
    }
    .img-container {
      justify-content: center;
    }
    .text__right {
      text-align: center;
      margin-bottom: 2.5rem;
    }
    p {
      margin-bottom: 0.5rem;
    }
    img {
      width: 25rem;
      height: auto;
    }
  }
  @media only screen and (max-width: 600px) {
    p {
      font-size: 1.5rem;
    }
    .text__right {
      font-size: 2rem;
    }
  }
</style>
