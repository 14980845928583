<template>
  <v-container class="about-container">
    <v-row class="d-flex justify-center animation-min-height">
      <v-col class="d-flex flex-column left-container pr-lg-10" lg="6" cols="12">
        <v-slide-x-transition>
          <h1 v-show="showAbout" class="header__text mb-md-16 mb-10">{{ $t('homePage.about') }}</h1>
        </v-slide-x-transition>
        <div v-intersect.once="onIntersectLogo" class="d-flex img-container mb-md-16 mb-10">
          <v-slide-x-transition>
            <img v-show="showLogo" :src="jambooksLogoLarge"/>
          </v-slide-x-transition>
        </div>
        <div v-intersect.once="onIntersectMakeYourMemories" class="d-flex flex-column">
          <v-slide-x-transition>
            <p v-show="showMakeYourMemories" class="fancy-font subheader__text">{{ $t('homePage.makeYourMemories') }}</p>
          </v-slide-x-transition>
          <v-slide-x-transition>
            <p v-show="showMakeYourMemories" class="fancy-font subheader__text">{{ $t('homePage.intentionalMoments') }}</p>
          </v-slide-x-transition>
          <v-slide-x-transition>
            <p v-show="showMakeYourMemories" class="fancy-font subheader__text">{{ $t('homePage.connectToPeople') }}</p>
          </v-slide-x-transition>
        </div>
        <div v-intersect.once="onIntersectEnd"></div>
      </v-col>
      <v-col class="mt-10" lg="6" cols="12">
        <v-slide-x-reverse-transition>
          <p v-show="showBestFriends" class="right-container__text right-container__text--bold mb-13">{{$t('homePage.bestFriends') }}</p>
        </v-slide-x-reverse-transition>
        <v-slide-x-reverse-transition>
          <p v-show="showQuarantine" class="right-container__text mb-13">{{ $t('homePage.quarantine') }}</p>
        </v-slide-x-reverse-transition>
        <v-slide-x-reverse-transition>
          <p v-show="showWendyAndDanny" class="right-container__text mb-lg-13 mb-0" v-html="$t('homePage.specialize')"></p>
        </v-slide-x-reverse-transition>
        <v-slide-x-reverse-transition>
          <p v-show="showJambooksWasBorn" class="right-container__text right-container__text--bold mb-lg-16 mb-md-15 mb-10">{{ $t('homePage.born') }}</p>
        </v-slide-x-reverse-transition>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col lg="6" cols="10">
        <v-btn class="btn btn--primary" color="primaryAction" block depressed>
          <span class="btn__text btn__text--white">{{ $t('homePage.learnMore') }}</span>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'About',
  data() {
    return {
      showAbout: false,
      showLogo: false,
      showMakeYourMemories: false,
      showBestFriends: false,
      showQuarantine: false,
      showWendyAndDanny: false,
      showJambooksWasBorn: false,
    }
  },
  computed: {
    jambooksLogoLarge() {
      return require('@/assets/Jambooks_Logo_Large.svg')
    }
  },
  methods: {
    onIntersectLogo(entries) {
      let vm = this
      setTimeout(function() {
        vm.showAbout = entries[0].isIntersecting;
      }, 400);
      this.onIntersectRight(entries);
    },
    onIntersectMakeYourMemories(entries) {
      let vm = this
      setTimeout(function() {
        vm.showLogo = entries[0].isIntersecting;
      }, 450);
    },
    onIntersectEnd(entries) {
      let vm = this
      setTimeout(function() {
        vm.showMakeYourMemories = entries[0].isIntersecting;
      }, 500);
    },
    onIntersectRight(entries) {
      let vm = this
      setTimeout(function() {
        vm.showBestFriends = entries[0].isIntersecting;
      }, 0);
      setTimeout(function() {
        vm.showQuarantine = entries[0].isIntersecting;
      }, 250);
      setTimeout(function() {
        vm.showWendyAndDanny = entries[0].isIntersecting;
      }, 500);
      setTimeout(function() {
        vm.showJambooksWasBorn = entries[0].isIntersecting;
      }, 750);
    },
  }
}
</script>

<style scoped>
.animation-min-height {
  min-height: 44rem;
}
.about-container {
  margin-bottom: 6.25rem;
}
.img-container {
  justify-content: flex-end;
}
.right-container__text {
  line-height: 150%;
}
.right-container__text--bold {
  font-weight: bold;
}
.header__text {
  font-size: 7.5rem;
  text-align: right;
}
.subheader__text {
  color: var(--v-text-base);
  text-align: right;
  margin-bottom: 0;
}
@media only screen and (max-width: 1264px) {
  .right-container__text {
    text-align: center;
  }
  .img-container {
    justify-content: center;
  }
  .header__text {
    text-align: center;
  }
  .subheader__text {
    text-align: center;
  }
}
@media only screen and (max-width: 960px) {
  img {
    width: 19rem;
    height: auto;
  }
  .header__text {
    font-size: 5rem;
  }
  .subheader__text {
    font-size: 1.2rem;
  }
  .right-container__text {
    font-size: 1.5rem;
  }
}
</style>
