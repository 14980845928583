export const MIN_PAGE_COUNT = 30;
export const MAX_PAGE_COUNT = 200;
export const BOOK_DETAILS = Object.freeze({
  hardCover: {
    width: '10.5"',
    height: '7"',
    price: "39",
    pricePer: "1.49",
  },
  softCover: {
    width: '9"',
    height: '6"',
    price: "29",
    pricePer: ".99",
  },
});