import { mutationNames } from '@/store/typesStore';
import { getterNames } from '@/store/typesStore';
import Http from '@/api/Http';

export const getTypes = async (store) => {
    if (!store.getters[getterNames.isTypeLoaded]) {
        // call api
        const api = Http.use('data');
        return api.get('/jams/types/', {lang: 'en'})
            .then(types => {
                let result = {
                    lang: 'en',
                    types: types
                };
                let payload = {
                    lang: 'en',
                    all: {},
                    ids: []
                }

                result.types.forEach(type => {
                    payload.all[type.id] = type;
                    payload.ids.push(type.id);
                });
                return store.commit(mutationNames.SAVE_TYPE, payload);
            })
            .catch(err => {
                console.log(err)
            })
    }
}
