<template>
  <div class="banner-container d-flex align-center justify-center">
    <div class="overlay" />
    <router-link class="banner-title" :to="{ path: '/products' }">
      {{ $t("homePage.checkoutOurProducts") }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: "ProductBanner",
  computed: {
    jambook() {
      return require("@/assets/Jambook_Front_Home.svg");
    },
  }
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/styles.sass";

.banner-container {
  position: relative;
  height: 240px;
  margin: 80px 0;
  background-image: url("../assets/pricing_feature_bg.jpeg");
  background-size: cover;
  background-position: center 60%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 240px;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.banner-title {
  position: absolute;
  top: 0%;
  right: 20%;
  color: var(--v-textOnSecondary-base);
  font-size: 32px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  padding: 20px;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.banner-title:hover {
  color: var(--v-tertiaryBackground-base);
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .overlay {
    opacity: 1;
  }
  .banner-title {
    position: relative;
    left: auto;
    right: auto;
  }
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .banner-title {
    font-size: 24px;
  }
}
</style>
