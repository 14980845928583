<template>
  <v-alert
      color="red"
      type="error"
      prominent
      v-if="error"
  >
    <v-row align="center">
      <v-col class="grow">
        <h3 style="color: #ffffff; text-align: center;" class="text-h5">{{ $t(error) }}</h3>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>

  export default {
    name: 'AlertError',
    props: {
      error: {
        type: String,
        default: null
      }
    }
  }
</script>
