<template>
<div class="repeat-image"></div>
</template>

<script>
  export default {
    name: 'FooterSkirt',
    data() {
      return {
        tileImage: require('@/assets/Jambooks_FooterSkirt_Tile.png'),
      }
    }
  }
</script>

<style>
  .repeat-image {
    background-image: url('../assets/Jambooks_FooterSkirt_Tile.png');
    background-repeat: repeat-x;
    height: 18.75rem;
  }
</style>
