import { render, staticRenderFns } from "./PricingCard.vue?vue&type=template&id=0c0ff75c&scoped=true&"
import script from "./PricingCard.vue?vue&type=script&lang=js&"
export * from "./PricingCard.vue?vue&type=script&lang=js&"
import style0 from "./PricingCard.vue?vue&type=style&index=0&id=0c0ff75c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c0ff75c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VScaleTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VCard,VCardText,VScaleTransition})


/* vuetify-loader */
import installDirectives from "!../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Intersect from 'vuetify/lib/directives/intersect'
installDirectives(component, {Intersect})
