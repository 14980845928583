<template>
  <v-container class="mb-10">
    <v-row class="d-flex justify-center">
      <v-avatar class="mr-8" color="primaryLight" :size="avatarSize" />

      <img v-if="step < 2" class="mr-8" :src="outlinedAvatar" alt="Step circle 1" />
      <v-avatar v-if="step >= 2" class="mr-8" color="text" :size="avatarSize" />

      <img v-if="step < 3" class="mr-8" :src="outlinedAvatar" alt="Step circle 2" />
      <v-avatar v-if="step >= 3" class="mr-8" color="secondary" :size="avatarSize" />

      <img v-if="step < 4" class="mr-8" :src="outlinedAvatar" alt="Step circle 3" />
      <v-avatar v-if="step === 4" class="mr-8" color="primaryAction" :size="avatarSize" />
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'ProgressAvatars',
    props: {
      step: {
        type: Number,
        default: 1
      }
    },
    computed: {
      avatarSize() {
        if (this.$vuetify.breakpoint.lgAndUp) {
          return 33
        } else {
          return 21
        }
      },
      outlinedAvatar() {
        return require('@/assets/Ellipse_Empty.svg')
      }
    }
  }
</script>

<style scoped>
  @media only screen and (max-width: 1264px) {
    img {
      width: 1.3rem;
    }
  }
</style>
