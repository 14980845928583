<template>
  <v-container fluid class="pa-0">
    <div class="header">
      <h1 class="header__title">{{ $t("about.title") }}</h1>
    </div>
    <v-container fluid class="team-content-container mx-auto pb-0">
      <div class="team-members">
        <TeamMember
            :name="$t('about.team.wendy.name')"
            :title="$t('about.team.wendy.title')"
            :description="{
            line1: $t('about.team.wendy.bio.line1'),
            line2: $t('about.team.wendy.bio.line2'),
            line3: $t('about.team.wendy.bio.line3'),
          }"
            :image1="'wendy_1.jpeg'"
            :image2="'wendy_2.jpeg'"
            :image3="'wendy_3.jpeg'"
        />
        <TeamMember
            imagesLeft
            :name="$t('about.team.bre.name')"
            :title="$t('about.team.bre.title')"
            :description="{
            line1: $t('about.team.bre.bio.line1'),
            line2: $t('about.team.bre.bio.line2'),
            line3: $t('about.team.bre.bio.line3'),
          }"
            :image1="'bre_1.jpeg'"
            :image2="'bre_2.jpeg'"
            :image3="'bre_3.jpeg'"
        />
        <TeamMember
            :name="$t('about.team.addison.name')"
            :title="$t('about.team.addison.title')"
            :description="{
            line1: $t('about.team.addison.bio.line1'),
            line2: $t('about.team.addison.bio.line2'),
            line3: $t('about.team.addison.bio.line3'),
          }"
            :image1="'addison_1.jpeg'"
            :image2="'addison_2.jpeg'"
            :image3="'addison_3.jpeg'"
        />
        <TeamMember
            imagesLeft
            :name="$t('about.team.danny.name')"
            :title="$t('about.team.danny.title')"
            :description="{
            line1: $t('about.team.danny.bio.line1'),
            line2: $t('about.team.danny.bio.line2'),
            line3: $t('about.team.danny.bio.line3'),
          }"
            :image1="'danny_1.jpeg'"
            :image2="'danny_2.jpeg'"
            :image3="'danny_3.jpeg'"
        />
        <TeamMember
            :name="$t('about.team.fred.name')"
            :title="$t('about.team.fred.title')"
            :description="{
            line1: $t('about.team.fred.bio.line1'),
            line2: $t('about.team.fred.bio.line2'),
            line3: $t('about.team.fred.bio.line3'),
          }"
            :image1="'fred_1.jpeg'"
            :image2="'fred_2.jpeg'"
            :image3="'fred_3.jpeg'"
        />
      </div>
      <div class="divider-image">
        <v-img src="../assets/FRUIT.svg" max-width="95px" />
      </div>
    </v-container>
    <div class="team-image d-flex align-center justify-center">
      <v-img
          class="px-0 px-sm-2"
          src="../assets/team/team_photo.jpeg"
          max-width="1280px"
          max-height="720px"
      />
    </div>
    <v-container fluid class="team-content-container mx-auto pt-0">
      <div v-intersect.once="(entries) => onIntersect(entries, 'showRow1')">
        <v-scale-transition>
          <v-row v-if="showRow1" no-gutters class="blurb">
            <v-col class="blurb-title col-12 col-sm-6 pr-sm-2 pr-md-9">
              <span> {{ $t("about.blurb.title.line1") }}<br /> </span>
              <span>
                {{ $t("about.blurb.title.line2.part1") }}
                <span class="mx-1" style="font-style: italic">{{
                    $t("about.blurb.title.line2.part2")
                  }}</span>
                {{ $t("about.blurb.title.line2.part3") }}
              </span>
            </v-col>
            <v-col
                class="blurb-content col-12 col-sm-6 right-column pl-sm-2 pl-md-9"
            >
              <p class="mb-7">{{ $t("about.blurb.text.paragraph1") }}</p>
              <p class="mb-7">
                {{ $t("about.blurb.text.paragraph2.part1") }}
                <span style="font-weight: 600">{{
                    $t("about.blurb.text.paragraph2.part2")
                  }}</span>
                {{ $t("about.blurb.text.paragraph2.part3") }}
              </p>
              <p>{{ $t("about.blurb.text.paragraph3") }}</p>
            </v-col>
          </v-row>
        </v-scale-transition>
      </div>
      <div style="min-height: 100px;" v-intersect.once="(entries) => onIntersect(entries, 'showRow2')">
        <v-scale-transition>
          <v-row v-if="showRow2" no-gutters class="call-to-action">
            <v-col class="action-text col-12 col-sm-6 pr-sm-3 pr-md-9">
              <h4>
                {{ $t("about.action.prompt.line1") }}<br />
                {{ $t("about.action.prompt.line2") }}<br />
                {{ $t("about.action.prompt.line3") }}
              </h4>
            </v-col>
            <v-col class="action-button col-12 col-sm-6 pl-sm-2 pl-md-9 my-auto">
              <v-btn class="btn btn--pattern" depressed :to="{ path: '/signup' }">
                <span class="btn__text btn__text--white">
                  {{ $t("about.action.buttonText") }}
                </span>
              </v-btn>
            </v-col>
          </v-row>
        </v-scale-transition>
      </div>
    </v-container>
  </v-container>
</template>

<script>
import TeamMember from "@/components/TeamMember.vue";
import AnimationTriggerMixin from "@/mixins/AnimationTriggerMixin";

export default {
  name: "AboutInDepth",
  components: {
    TeamMember,
  },
  mixins: [AnimationTriggerMixin],
  data() {
    return {
      showRow1: false,
      showRow2: false,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/styles.sass";

.header {
  display: flex;
  width: 100%;
  height: 412px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: var(--v-secondary-base);
}

.header__title {
  color: var(--v-textOnSecondary-base);
  font-size: 96px;
  line-height: 150%; /* 144px */
}

.team-content-container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 80px;
  width: auto;
}

.team-members {
  display: flex;
  flex-direction: column;
  gap: 170px;
}

.divider-image {
  margin: 110px auto 140px;
  width: fit-content;
}

.team-image {
  height: auto;
  overflow: hidden;
  margin: 0 10px 70px;
}

.btn {
  background: url(../assets/Start_Preserving_Background.svg);
  background-size: cover;
  width: 414px;
  max-width: 100%;
}

.blurb {
  margin-bottom: 250px;
}

.blurb-title {
  text-align: right;
}

.blurb-title span {
  text-align: right;
  font-size: 48px;
  font-weight: 600;
  line-height: 150%; /* 72px */
}

.blurb-content p {
  max-width: 500px;
  font-size: 24px;
  font-weight: 400;
  line-height: 150%; /* 36px */
}

.action-text h4 {
  text-align: right;
  color: var(--v-primaryAction-base);
  font-family: "PhosphatePro";
  font-size: 24px;
  font-weight: normal;
  line-height: 150%;
  text-transform: uppercase;
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .header {
    height: 340px;
  }

  .team-content-container {
    padding: 100px 30px;
  }

  .team-members {
    gap: 140px;
  }

  .divider-image {
    margin: 75px auto 95px;
  }

  .team-image {
    margin-bottom: 60px;
  }

  .btn {
    max-width: 290px;
  }

  .blurb {
    margin-bottom: 100px;
  }

  .blurb-title span {
    font-size: 32px;
    font-weight: 700;
  }

  .blurb-content {
    max-width: 320px;
  }

  .blurb-content p {
    font-size: 24px;
    font-weight: 400;
    line-height: 150%; /* 36px */
  }

  .action-text h4 {
    font-size: 18px;
  }
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .header {
    height: 228px;
  }

  .header__title {
    font-size: 64px;
    line-height: 111.5%; /* 71.36px */
  }

  .team-members {
    gap: 80px;
  }

  .divider-image {
    margin: 20px auto 50px;
  }

  .team-image {
    aspect-ratio: 1.5;
    margin: 0 0 50px;
  }

  .team-image .v-image {
    height: 100%;
    width: 100%;
  }

  .btn {
    margin: 0 auto;
  }

  .blurb {
    margin: 0 auto;
  }

  .blurb-title {
    text-align: center;
  }

  .blurb-content {
    max-width: 100%;
  }

  .blurb-content p {
    text-align: center;
  }

  .action-text h4 {
    margin: 40px auto;
    text-align: center;
  }

  .action-button {
    display: flex;
  }
}
</style>
