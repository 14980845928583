<template>
  <v-card
      color="primary"
      dark
  >
    <v-card-text class="pa-6 text-h5">
      {{  text }}
      <v-progress-linear
          indeterminate
          color="white"
          class="mb-0 mt-5"
      />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'DialogProgress',
  props: {
    text: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  }
}
</script>