// export const validatePhone = (phone) => phone.match(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
export const validatePhone = (phone) => /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(phone);

export const frequency = [
    'prompts.oneTimeDay',
    'prompts.twoTimeDay',
    'prompts.threeTimeDay',
    'prompts.oneTimeWeek',
    'prompts.twoTimeWeek',
    'prompts.threeTimeWeek',
];

export const timeZones  = {
    'America/Los_Angeles': 'tz.PST',
    'America/Phoenix': 'tz.PNT',
    'America/Denver': 'tz.MST',
    'America/Chicago': 'tz.CST',
    'America/New_York': 'tz.EST',
    'Pacific/Honolulu': 'tz.HST'
};

export const getFormattedPhone = (phone) => {
    if (!phone) {
        return phone;
    } else {
        let match = phone.match(/^\+?1?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3]
        }
        return phone;
    }
};
