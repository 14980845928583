<template>
  <v-container>
    <h2 class="header header-span mb-8">{{ $t('when.header') }}</h2>
    <v-row class="d-flex flex-column align-center">
      <v-col class="d-flex flex-column" cols="12">
        <span class="field-box__text">{{ $t('when.subHeader') }}</span>
      </v-col>
    </v-row>
    <v-row class="d-flex d-sm-flex">
      <v-col cols="12" md="6">
        <v-alert
          color="secondary"
          prominent
        >
          <v-row align="center">
            <v-col>
              <h3 style="color: #ffffff; text-align: center;" class="text-h5">{{ $t('when.startDate') }}
                {{ startDate }}</h3>
            </v-col>
          </v-row>
        </v-alert>
        <v-date-picker
            v-model="form.startDate"
            color="green lighten-2"
            full-width
            elevation="2"
            no-title
            :min="new Date().toISOString().substr(0, 10)"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-alert
            color="secondary"
            prominent
        >
          <v-row align="center">
            <v-col>
              <h3 style="color: #ffffff; text-align: center;" class="text-h5">
                {{ prettyEndDate }}</h3>
            </v-col>
          </v-row>
        </v-alert>
        <v-date-picker
            v-model="form.endDate"
            header-color="white"
            color="green lighten-2"
            full-width
            no-title
            elevation="2"
            :disabled="form.neverEnds"
            :min="new Date().toISOString().substr(0, 10)"
        />
        <v-switch
          :label="$t('when.noEnd')"
          v-model="form.neverEnds"
          />
      </v-col>
    </v-row>
    <AlertError :error="error" />
    <v-row>
      <v-col style="text-align: right">
        <v-btn class="btn btn--accent mb-5" color="text" block @click="submit" :disabled="!isValid">
            <span class="btn__text btn__text--white">
              {{ $t('createJamPage.go') }}
            </span>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AlertError from '@/components/AlertError';

  export default {
    name: 'When',
    components: {
      AlertError
    },
    props: {
      jam: {
        type: Object,
        default: () => ({startDate: null, endDate: null, neverEnds: false})
      },
    },
    data() {
      return {
        startDate: null,
        endDate: null,
        noEndDate: false,
        error: '',
        form: {...this.jam}
      }
    },
    computed: {
      isValid () {
        if (this.form.startDate && (this.form.endDate || this.form.neverEnds)) {
          return true;
        } else {
          return false;
        }
      },
      prettyEndDate () {
        if (this.neverEnds) {
          return this.$t('when.noEnd');
        } else {
          return this.$t('when.endDate') + ' ' + (this.endDate ? this.endDate : '');
        }
      }
    },
    methods: {
      submit () {
        if (this.validate()) {
          if (this.form.neverEnds) {
            this.form.endDate = null;
          }
          this.$emit('save', this.form);
        }
      },
      validate() {
        this.error = '';

        if (!this.form.startDate) {
          this.error = 'when.emptyStart';
        }

        if (!this.error && !this.form.endDate && !this.form.neverEnds) {
          this.error = 'when.emptyEnd';
        }

        if (!this.error && (Date.parse(this.form.endDate + ' 12:00:00 AM') < Date.parse(this.form.startDate  + ' 12:00:00 AM'))) {
          this.error = 'when.endBeforeStart';
        }

        if (!this.error && (Date.parse(this.form.startDate + ' 12:00:00 AM') < (new Date()).setUTCHours(0, 0, 0, 0))) {
          this.error = 'when.startInPastError';
        }

        return !this.error;
      }
    },
    watch: {
      jam: function(newVal) {
        this.form = {...newVal};
      }
    }
  }
</script>

<style scoped>
.header {
  color: var(--v-primary-base);
  text-align: center;
  line-height: 100%;
}
.field-box__text {
  font-size: 1.75rem;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}
</style>
