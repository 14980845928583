import axios from 'axios';

const instance = axios.create({
    baseURL: '/api'
});

export default instance;

// const get = async (url) => {
//     return await axios.get(url);
//     // axios.get(url)
//     //     .catch((e) => {
//     //         console.log(e.response.data);
//     //     })
//     // try {
//     //     return await axios.get(url);
//     // } catch (e) {
//     //     console.log(e);
//     // }
//
//     // let ret = await axios.get(url);
//
//     // if (ret.data.error) {
//     //     throw(ret.data.errors);
//     // }
//     // return ret.data;
// }
//
// const post = async (url, data) => {
//     let ret = await axios.post(url, data);
//     if (ret.data.error) {
//         throw(ret.data.errors);
//     }
//     return ret.data.data;
// }
//
// const checkAuthAsync = async () => {
//     return await get('/api/auth/check');
// }
//
// const loginAsync = async (phone, pass) => {
//     return await post('/api/auth/login', {phone, pass});
// }
//
// export { checkAuthAsync, loginAsync };
