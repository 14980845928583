<template>
  <v-text-field
    class="text-h4"
    v-bind="$props"
    v-on="$listeners"
    flat
    outlined
    hide-details="auto"
    color="var(--v-secondary-base)"
    ref="input"
  >
  </v-text-field>
</template>

<script>
import { VTextField } from 'vuetify/lib';

  export default {
    name: 'TextField',
    extends: VTextField,
    props: {
      autocomplete: {
        type: String
      },
      disabled: {
        type: Boolean
      }
    }
  }

</script>

<style>
.v-input input {
  max-height: 100px;
}
.v-input .v-label {
  height: 20px;
  line-height: 20px;
  letter-spacing: normal;
}
</style>
