<template>
  <v-container class="px-0 pb-0" fluid>
    <v-container>
      <h2 class="header header-span mb-8">{{ $t('pay.creating') }}</h2>
      <p class="card-text text-center">
        {{ $t('pay.notice') }}
      </p>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: 'JamCreateFinish.vue'
}
</script>

<style scoped>
.header {
  /*color: var(--v-secondary-base);*/
  text-align: center;
  line-height: 100%;
}
</style>