<template>
  <v-text-field

      v-bind="$props"
      v-on="$listeners"
      class="rounded_text_input"
      outlined
      solo
      flat
      @keyup.enter="$emit('onEnter')"
      hide-details="auto"
  />
</template>

<script>
import { VTextField } from 'vuetify/lib';
export default {
  name: "RoundedTextField",
  extends: VTextField,
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    strokeVariant: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      currentValue: this.value,
    }
  },
  watch: {
    currentValue (val) {
      this.$emit("input", val);
    },
    value (val) {
      this.currentValue = val;
    },
  },
};
</script>