<template>
  <v-container class="pa-0">
    <v-hover :disabled="avatarHover">
      <template v-slot:default="{ hover }">
        <v-avatar
          class="my-2 mx-lg-4"
          :color="avatarColor"
          :size="size"
        >
          <span class="avatar-text">{{ avatarText }}</span>
          <v-slide-x-transition>
            <v-overlay
              v-if="hover"
              absolute
              :color="avatarColor"
              opacity=1
            >
              <v-img class="avatar-img" :src="avatarImage"></v-img>
            </v-overlay>
          </v-slide-x-transition>
        </v-avatar>
      </template>
    </v-hover>
  </v-container>
</template>

<script>
  export default {
    name: 'Avatar',
    props: {
      avatar: {
        type: Object
      },
      commonAvatar: {
        type: Object
      },
      size: {
        type: Number
      }
    },
    data() {
      return {
        avatarColor: this.avatar.color,
        avatarImage: this.avatar.image,
        avatarText: this.avatar.text,
        avatarHover: this.commonAvatar.hoverDisabled,
      }
    }
  }
</script>

<style>
  .v-image__image--cover {
    background-size: auto;
  }
  .avatar-text {
    font-weight: bold;
    font-size: 4rem;
    color: white;
  }
  @media only screen and (max-width: 1264px) {
    .avatar-text {
      font-size: 2rem;
    }
  }
</style>
