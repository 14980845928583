<template>
  <div>
    <div class="input-container px-md-0 mb-md-8">
      <RoundedTextField
          type="text"
          v-model="phoneNumber"
          :label="$t('signUpPage.textVerification.enterNumber')"
          :rules="[rules.required, rules.phone]"
          ref="txtPhone"
      />
    </div>
    <div class="input-container px-md-0">
      <AlertError :error="error" />
      <DefaultButton
          class="pattern1_btn"
          color="secondary"
          block
          @click="onSubmit"
      >
        {{ $t("signUpPage.textVerification.startPreserving") }}
      </DefaultButton>
    </div>
    <v-dialog v-model="dialog" max-width="970">
      <v-card color="tertiaryBackground pa-5 pa-sm-12">
        <div class="confirmation-container">
          <v-card-title class="confirmation-title d-flex flex-column align-center">
            <div class="confirmation-image mb-4">
              <img
                  src="../assets/send.svg"
                  alt="check phone image"
              />
            </div>
            <h3>
              {{ $t("signUpPage.textVerification.confirmation.title") }}
            </h3>
          </v-card-title>
          <v-card-subtitle class="confirmation-subtitle pa-0 mb-3">
            <h4>{{ $t("signUpPage.textVerification.confirmation.subtitle", { number: formattedPhone }) }}</h4>
            <AlertError :error="verifyError" />
          </v-card-subtitle>
          <v-card-text>

            <TextField
                v-model="confirmationCode"
                type="text"
                :placeholder="$t('signUpPage.textVerification.confirmation.inputPlaceholder')"
                class="mb-5"
            />
            <DefaultButton
                color="secondary"
                @click="onResend"
                :loading="resendLoading"
            >
              {{ $t('signUpPage.textVerification.resend') }}
            </DefaultButton>
            <DefaultButton
                class="confirm-btn"
                color="text"
                :disabled="!confirmationCode"
                @click="onVerify"
            >
              {{ $t("signUpPage.textVerification.confirmation.confirm") }}
            </DefaultButton>
          </v-card-text>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import AlertError from '@/components/AlertError';
import RoundedTextField from '@/components/inputs/RoundedTextField.vue';
import DefaultButton from '@/components/buttons/DefaultButton.vue';
import { validatePhone, getFormattedPhone } from '@/lib';
import TextField from '@/components/TextField.vue';
import Http from '@/api/Http';
import { getterNames, mutationNames } from '@/store/global'

export default {
  components: {
    AlertError,
    TextField,
    DefaultButton,
    RoundedTextField
  },
  data() {
    return {
      api: Http.use('data'),
      confirmationCode: '',
      dialog: false,
      error: null,
      verifyError: null,
      phoneNumber: '',
      resendLoading: false,
      rules: {
        required: value => !!value || this.$t('login.required'),
        phone: value => validatePhone(value) || this.$t('login.badPhone')
      },
    }
  },
  computed: {
    formattedPhone() {
      return getFormattedPhone(this.phoneNumber)
    },
    regPhone: function () {
      return this.$store.getters[getterNames.regPhone];
    }
  },
  methods: {
    onSubmit() {
      this.error = null;
      if (!this.$refs.txtPhone.validate()) {
        this.error = this.$t('login.badPhone');
      } else {
        this.api.post('/auth/join', { phone: this.phoneNumber.replace(/\D/g,'') })
            .then(() => {
              return this.$store.commit(mutationNames.SAVE_REG_PHONE, this.phoneNumber);
            })
            .then(() => {
              // this.$router.push('/signup/register');
              this.dialog = true;
            })
            .catch(err => {
              this.error = err.response.data.error;

            })
        // this.dialog = true;
      }
    },
    onVerify() {
      this.api.post('/auth/verifyCode', { code: this.confirmationCode })
          .then(() => {
            this.$router.push('/signup/register');
          })
          .catch(err => {
            this.verifyError = err.response.data.error;
          })
      // console.log(this.confirmationCode);
    },
    onResend() {
      this.resendLoading = true;
      this.verifyError = null;
      this.api.post('/auth/join', { phone: this.regPhone })
          .then(() => {
            this.resendLoading = false;
          })
          .catch((err) => {
            this.resendLoading = false;
            this.error = err.response.data.error;
          });
    }
  }
}
</script>

<style lang="scss" scoped>
.confirmation-container {
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
}

.confirmation-title h3 {
  color: var(--v-secondaryText-base);
  font-size: 36px;
  font-weight: 600;
  line-height: 150%; /* 54px */
  word-break: normal;
}

.confirmation-subtitle h4 {
  color: var(--v-secondaryText-base);
  font-size: 24px;
  font-weight: 400;
  line-height: 150%; /* 36px */
}

</style>