<template>
  <v-card flat>
    <NavigationDrawer v-model="drawer" @close="drawer = false"/>
    <v-app-bar class="app-bar mx-md-15 mb-md-10 mb-sm-10 mb-5" flat color="white" prominent>
      <v-row class="d-flex justify-space-between align-center">
        <v-col class="d-flex">
          <a @click="handleClick">
            <img class="app-bar__logo" :src="jambooksLogo"/>
          </a>
        </v-col>
        <v-col cols="auto" class="d-flex justify-end align-center">
          <v-row>
            <v-col class="d-none d-md-block" cols="auto">
              <span class="app-bar-items__text mr-10 d-none d-sm-flex">
                <a href="http://help.jambooks.co" target="_blank">{{ $t('appBar.help') }}</a>
              </span>
            </v-col>
            <v-col class="d-none d-md-block" cols="auto" v-if="!currentUser">
              <router-link to="/login">
                <span class="app-bar-items__text mr-10 d-none d-sm-flex">
                  {{ $t('appBar.login') }}
                </span>
              </router-link>
            </v-col>
            <v-col cols="auto">
              <div class="d-flex flex-column align-center">
              <v-btn class="mb-sm-4" icon x-large @click.stop="drawer = !drawer">
                <v-avatar :size="avatarSize" color="primaryAction">
                  <img class="app-bar-menu__img" :src="peach"/>
                </v-avatar>
              </v-btn>
                <span class="app-bar-items__text app-bar-items__text--small">{{ $t('appBar.menu') }}</span>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-app-bar>
  </v-card>
</template>

<script>
import NavigationDrawer from '@/components/NavigationDrawer';
import { getterNames } from '@/store/global';

export default {
  name: 'AppBar',
  components: {
    NavigationDrawer,
  },
  data() {
    return {
      drawer: null,
    }
  },
  methods: {
    handleClick: function () {
      if (this.currentUser) {
        if (this.$route.path !== '/jams') {
          this.$router.push('/jams');
        }
      } else {
        if (this.$route.path !== '/') {
          this.$router.push('/');
        }
      }
    }
  },
  computed: {
    jambooksLogo() {
      return require('@/assets/Jambooks_Logo.svg')
    },
    peach() {
      return require('@/assets/Peach_AppBar.svg')
    },
    avatarSize() {
      if (this.$vuetify.breakpoint.smAndUp) {
        return 85
      } else {
        return 40
      }
    },
    currentUser: function () {
      return this.$store.getters[getterNames.currentUser];
    }
  }
}
</script>

<style scoped>
  a { text-decoration: none; }
  .app-bar {
    /*padding: 2rem;*/
  }
  .app-bar-items__text {
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: bold;
  }
  .app-bar-items__text a {
    color: var(--v-text-base);
    font-weight: bold;
  }
  .app-bar-items__text--small{
    font-size: 1rem;
  }
  .app-bar-menu__img {
    width: 3.75rem;
    height: auto;
  }

  @media only screen and (max-width: 600px) {
    .app-bar__logo {
      width: 9.5rem;
      height: auto;
    }
    .app-bar-menu__img {
      width: 1.75rem;
      height: auto;
    }
  }
</style>
