<template>
  <v-navigation-drawer
    v-bind:value="value"
    v-on="$listeners"
    app
    temporary
    right
    :width="width"
  >
    <v-list-item class="ma-10">
      <v-row class="d-flex justify-space-between align-center">
        <img class="nav-drawer__logo-img" :src="logoDrawer" alt="menu open" @click="handleHomeClick" style="cursor: pointer;"/>
        <v-btn icon x-large @click="closeBtnClick">
          <img class="nav-drawer__close-img" alt="menu close" :src="closeDrawer"/>
        </v-btn>
      </v-row>
    </v-list-item>
    <v-divider></v-divider>
    <v-list>

<!--      <v-list-group-->
<!--          :value="true"-->
<!--          no-action-->
<!--          v-if="items.jam"-->
<!--          @click="onJamTitleClick"-->
<!--      >-->
<!--        <template v-slot:activator>-->
<!--          <v-list-item-content>-->
<!--            <v-list-item-title class="list-item__title list-group-title">{{ currentName }}</v-list-item-title>-->
<!--          </v-list-item-content>-->
<!--        </template>-->

<!--        <v-list-item-->
<!--            v-for="([title, route], i) in items.jam"-->
<!--            :key="i"-->
<!--            link-->
<!--            :to="route"-->
<!--        >-->
<!--          <v-list-item-title class="list-item__title" v-text="title"></v-list-item-title>-->
<!--        </v-list-item>-->
<!--      </v-list-group>-->

<!--      <v-divider v-if="items.jam"></v-divider>-->
      <v-list-item
        v-for="item in items"
        :key="item.title"
        link
        class="list-item"
        :to="item.route"
        :exact="true"
      >
        <v-list-item-content>
          <v-list-item-title class="list-item__title">{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-group
        v-if="items.account"
        no-action
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title class="list-item__title list-group-title">Account</v-list-item-title>
          </v-list-item-content>
        </template>
        <v-list-item
            v-for="([title, route], i) in items.account"
            :key="i"
            link
            :to="route"
        >
          <v-list-item-title class="list-item__title" v-text="title"></v-list-item-title>
        </v-list-item>
      </v-list-group>

      <v-list-group
          no-action
          v-if="items.admin"
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title class="list-item__title list-group-title">Admin</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
            v-for="([title, route], i) in items.admin"
            :key="i"
            link
            :to="route"
        >
          <v-list-item-title v-text="title" class="list-item__title"></v-list-item-title>
        </v-list-item>
      </v-list-group>
      <v-list-item
          link
          class="list-item nav_help"
          @click="onHelp"
      >
        <v-list-item-content>
          <v-list-item-title class="list-item__title">Help</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
          v-if="currentUser"
          link
          class="list-item"
          @click="logout"
      >
        <v-list-item-content>
          <v-list-item-title class="list-item__title">Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { logout } from '@/services/authService';
import { getterNames } from '@/store/jamStore';

  export default {
    name: 'NavigationDrawer',
    props: {
      value: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        name: '',
        jamName: 'KAUAI ANNIVERSARY',
        jamExpand: true,
        // items: [
        //   { title: this.$t('navDrawer.home'), route: '/' },
        //   { title: this.$t('navDrawer.create'), route: '/create' },
        //   { title: this.$t('navDrawer.signUp'), route: '/signup' },
        //   { title: this.$t('navDrawer.account'), route: '/account' },
        // ],
      }
    },
    computed: {
      currentName() {
        const sel = this.$store.getters[getterNames.getSelected];
        if (sel) {
          return sel.name;
        } else {
          return '';
        }
      },
      width() {
        if (this.$vuetify.breakpoint.smAndUp) {
          return '50%'
        } else {
          return '100%'
        }
      },
      logoDrawer() {
        return require('@/assets/Logo_Drawer.svg')
      },
      closeDrawer() {
        return require('@/assets/Drawer_Close.svg')
      },
      currentUser: function () {
        return this.$store.state.user;
      },
      items: function () {
        let menu = [];
        if (this.currentUser) {
          menu = [
            { title: 'Jams', route: '/jams', exact: true },
            // { title: 'Create', route: '/create' },
            // { title: this.$t('navDrawer.account'), route: '/account/profile' },
          ];

          if (this.currentName) {
            menu.jam = [
              ['Jammers', `/jams/${this.$store.state.jam.selectedId}/jammers`],
              ['Prompts', `/jams/${this.$store.state.jam.selectedId}/prompts`],
              ['Book', `/jams/${this.$store.state.jam.selectedId}/layout`]
            ];
          }

          menu.account = [
            ['Profile', '/account/profile'],
            ['Orders', '/account/orders']
          ];
          // if (this.$route.path.match('/account/')) {
          //   menu.push({ title: 'Profile', route: '/account/profile', indent: true });
          //   menu.push({ title: 'Billing', route: '/account/billing', indent: true });
          // }

          if (this.currentUser.type === 'admin') {
            menu.admin = [
              ['Prompts', '/admin/prompts'],
              ['Users', '/admin/users']
            ];
          }
          return menu;
        } else {
          return [
            // { title: this.$t('navDrawer.home'), route: '/' },
            { title: this.$t('nav.login'), route: '/login' },
            { title: this.$t('nav.pricingProducts'), route: '/products' },
            { title: this.$t('nav.moreWaysToJam'), route: '/ways' },
            // { title: this.$t('nav.community'), route: '/community' },
            { title: this.$t('nav.privacy'), route: '/privacy' },
            { title: this.$t('nav.about'), route: '/about' }
          ]
        }
      }
    },
    methods: {
      onJamTitleClick() {
        if (this.$route.path !== `/jams/${this.$store.state.jam.selectedId}`) {
          this.$router.push(`/jams/${this.$store.state.jam.selectedId}`);
        }
      },
      handleHomeClick() {
        if (this.$route.path !== '/') {
          this.$router.push('/');
        }
        this.$emit('close');
      },
      closeBtnClick() {
        this.$emit('close');
      },
      logout() {
        logout(this.$store);
        this.$router.push('/');
      },
      onHelp() {
        window.open('http://help.jambooks.co', '_blank', 'noreferrer');
      }
    }
  }

</script>

<style scoped>
  .list-group-title {
    padding-left: 25px;
    overflow: clip;
  }

  .list-item {
    /*text-align: center;*/
    padding-left: 40px;
  }
  .list-item__title {
    font-size: 3.75rem;
    font-weight: bold;
    color: var(--v-primaryAction-base);
    text-transform: uppercase;
  }
  .nav_help {
    visibility: hidden;
    display: none;
  }

  @media only screen and (max-width: 600px) {
    .nav_help {
      visibility: visible;
      display: block;;
    }
  }

  @media only screen and (max-width: 960px) {
    .nav-drawer__logo-img {
      width: 11.25rem;
      height: auto;
    }
    .nav-drawer__close-img {
      width: 1.25rem;
      height: auto;
    }
  }
</style>
