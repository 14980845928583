<template>
  <div class="phone-frame">
    <div class="phone-ear"></div>
    <div class="phone-screen">
      <img v-if="img" :src="img.src" :width="img.width">
      <div class="text-bubble">
        {{ message }}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      message: {
        type: String,
        default: ''
      },
      img: {
        type: Object
      }
    },
    data () {
      return {};
    }
  }
</script>

<style scoped>
.phone-frame {
  border-top-left-radius: 37px;
  border-top-right-radius: 37px;
  border-top: #e3e8ee 1px solid;
  border-left: #e3e8ee 1px solid;
  border-right: #e3e8ee 1px solid;
  background-color: #fff;
  height: 95%;
  width: 390px;
  padding-top: 10px;

}
.phone-screen {
  border-top-left-radius: 37px;
  border-top-right-radius: 37px;
  border-top: #e3e8ee 1px solid;
  border-left: #e3e8ee 1px solid;
  border-right: #e3e8ee 1px solid;
  padding-right: 10px;
  padding-top: 20px;
  padding-bottom: 10px;
  margin: 10px;
  height: 90%;
}
.phone-ear {
  border-radius: 5px;
  height: 8px;
  margin: 10px;
  margin-top: 8px;
  margin-left: 160px;
  width: 20%;
  background-color: #e3e8ee;
}
.text-bubble {
  width: 290px;
  background-color: #E1F5FEFF;
  border-radius: 12px;
  padding: 10px;
  margin-left: 60px;
  font-size: 1.2em;
  color: #000;
}
</style>