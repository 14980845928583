<template>
  <v-container class="mb-10">
    <v-row class="d-flex justify-center animation-min-height" align="end">
      <v-col class="d-flex justify-end align-end pa-0" md="6" cols="5">
        <v-slide-y-reverse-transition>
          <h3 v-show="showSoFreakingSimple" class="header-span pa-0 mr-2">{{ $t('homePage.its') }}</h3>
        </v-slide-y-reverse-transition>
      </v-col>
      <v-col class="ma-0 pa-0 align-end" md="6" cols="7">
        <v-slide-y-reverse-transition>
          <h2 v-show="showSoFreakingSimple" class="header-span">{{ $t('homePage.soFreakingSimple') }}</h2>
        </v-slide-y-reverse-transition>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center animation-min-height" v-intersect.once="onIntersectBestMemories" align="end">
      <v-col class="ma-0 pa-0 d-flex justify-end align-end" md="6" cols="5">
        <v-slide-y-reverse-transition>
          <h3 v-show="showBestMemories" class="header-span pa-0 mr-2">{{ $t('homePage.toPreserve') }}</h3>
        </v-slide-y-reverse-transition>
      </v-col>
      <v-col class="ma-0 pa-0" md="6" cols="7">
        <v-slide-y-reverse-transition>
          <h2 v-show="showBestMemories" class="header-span">{{ $t('homePage.bestMemories') }}</h2>
        </v-slide-y-reverse-transition>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center animation-min-height" v-intersect.once="onIntersectBestPeople" align="end">
      <v-col class="ma-0 pa-0 d-flex justify-end align-end" md="6" cols="5">
        <v-slide-y-reverse-transition>
          <h3 v-show="showBestPeople" class="header-span pa-0 mr-2">{{ $t('homePage.withYour') }}</h3>
        </v-slide-y-reverse-transition>
      </v-col>
      <v-col class="ma-0 pa-0" md="6" cols="7">
        <v-slide-y-reverse-transition>
          <h2 v-show="showBestPeople" class="header-span">{{ $t('homePage.bestPeople') }}</h2>
        </v-slide-y-reverse-transition>
      </v-col>
    </v-row>
    <v-row v-intersect.once="onIntersectLife" class="mt-8 ml-15 animation-min-height" align="end">
      <v-col class="d-flex justify-center">
        <v-slide-y-reverse-transition>
          <img v-show="showLife" :src="lifeImg"/>
        </v-slide-y-reverse-transition>
      </v-col>
    </v-row>
    <v-row v-intersect.once="onIntersectEnd" ></v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'SimpleText',
    data() {
      return {
        show: false,
        showSoFreakingSimple: false,
        showBestMemories: false,
        showBestPeople: false,
        showLife: false,
      }
    },
    computed: {
      lifeImg() {
        return require('@/assets/Life.svg')
      }
    },
    methods: {
      onIntersectBestMemories (entries) {
        let vm = this
        setTimeout(function() {
          vm.showSoFreakingSimple = entries[0].isIntersecting;
        }, 300);
      },
      onIntersectBestPeople(entries) {
        let vm = this
        setTimeout(function() {
          vm.showBestMemories = entries[0].isIntersecting;
        }, 400);
      },
      onIntersectLife(entries) {
        let vm = this
        setTimeout(function() {
          vm.showBestPeople = entries[0].isIntersecting;
        }, 450);
      },
      onIntersectEnd(entries) {
        let vm = this
        setTimeout(function() {
          vm.showLife = entries[0].isIntersecting;
        }, 500);
      }
    }
  }
</script>

<style scoped>
.animation-min-height {
  min-height: 5rem;
}
h2 {
  line-height: 125%;
}
@media only screen and (min-width: 961px) and (max-width: 1264px) {
  h3 {
    font-size: 2.5rem;
  }
  h2 {
    font-size: 3.75rem;
  }
}
@media only screen and (max-width: 960px) {
  h3 {
    font-size: 1rem;
  }
  h2 {
    font-size: 2rem;
  }
  img {
    width: 20rem;
    height: auto;
  }
}

</style>