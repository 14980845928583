<template>
  <v-btn
    v-bind="$attrs"
    class="color_btn py-2 py-sm-3"
    border-radius="10px"
    dark
    depressed
    @click="$emit('click')"
  >
    <slot />
  </v-btn>
</template>

<script>
export default {
  name: "DefaultButton"
}
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/styles.sass";

.color_btn {
  width: 179px;
  max-height: unset;
  border-width: 4px;
}
</style>
