export const getterNames = {
    currentUser: 'currentUser',
    currentLang: 'currentLang',
    regPhone: 'regPhone',
    loading: 'getLoading',
    unauthPath: 'getUnauth',
    getStripeKey: 'getStripeKey'
}

export const mutationNames = {
    UPDATE_CURRENT_USER: 'updateCurrentUser',
    DELETE_USER: 'deleteUser',
    UPDATE_AUTHENTICATED: 'updateAuthenticated',
    SAVE_UNAUTH_PATH: 'saveUnauthPath',
    SAVE_REG_PHONE: 'saveRegPhone',
    LOADING: 'setLoadings',
    SET_STRIPE_KEY: 'setStripeKey'
}

export const createInitialState = () => ({
    isAuthenticated: false,
    user: false,
    lang: 'en',
    unauthPath: '',
    regPhone: '',
    isLoading: true,
    stripeKey: ''
});

function createModule() {
    return {
        state:  () => createInitialState(),
        getters: {
            [getterNames.currentLang]: (state) => (state.lang),
            [getterNames.currentUser]: (state) => (state.user),
            [getterNames.regPhone]: (state) => (state.regPhone),
            [getterNames.loading]: (state) => (state.isLoading),
            [getterNames.unauthPath]: (state) => (state.unauthPath),
            [getterNames.getStripeKey]: (state) => (state.stripeKey)
         },
        mutations: {
            [mutationNames.UPDATE_AUTHENTICATED]: (state, isAuth) => {
                state.isAuthenticated = isAuth;
            },
            [mutationNames.UPDATE_CURRENT_USER]: (state, payload) => {
                if (payload) {
                    state.user = payload;
                    state.isAuthenticated = true;
                } else {
                    state.user = false;
                    state.isAuthenticated = false;
                    state.unauthPath = '';
                }
            },
            [mutationNames.DELETE_USER]: (state) => {
                delete state.user;
            },
            [mutationNames.SAVE_UNAUTH_PATH]: (state, payload) => {
                state.unauthPath = payload;
            },
            [mutationNames.SAVE_REG_PHONE]: (state, phone) => {
                state.regPhone = phone;
            },
            [mutationNames.LOADING]: (state, payload) => {
                state.isLoading = payload;
            },
            [mutationNames.SET_STRIPE_KEY]: (state, payload) => {
                state.stripeKey = payload;
            }
        }
    }
}

export { createModule };
