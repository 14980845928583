<template>
  <v-container
    fluid
    v-intersect.once="(entries) => onIntersect(entries, 'show')"
    :class="`team-member-container pa-0 d-flex jutify-center align-center ${flexDirection}`"
  >
    <v-slide-y-transition>
      <div
        v-if="show"
        :class="`text-content d-flex flex-column justify-center ${leftAlign ? 'pl-sm-7 pl-md-13' : 'pr-sm-7 pr-md-13'} ${flexAlign}`"
      >
        <h3 :class="`team-member-title ${textAlignment}`">{{ title }}</h3>
        <p :class="`bio-details ma-0 ${textAlignment}`">
          {{ description.line1 }}<br/>
          {{ description.line2 }}<br/>
          {{ description.line3 }}
        </p>
        <h2 :class="`d-none d-sm-inline name ${textAlignment}`">{{ name }}</h2>
      </div>
    </v-slide-y-transition>
    <v-scale-transition>
      <div v-if="show" :class="`images d-flex align-center flex-column flex-sm-row`">
        <div class="team-image">
          <v-img
            :src="getUrl(image1)"
            cover
            width="100%"
            height="100%"
          />
        </div>
        <div class="team-image featured py-1 py-sm-0 px-sm-1">
          <v-img
            :src="getUrl(image2)"
            cover
            width="100%"
            height="100%"
            :position="$vuetify.breakpoint.xs ? 'center 10%' : 'center center'"
          />
        </div>
        <div class="team-image">
          <v-img
            :src="getUrl(image3)"
            cover
            width="100%"
            height="100%"
          />
        </div>
      </div>
    </v-scale-transition>

    <h2 :class="`d-sm-none name ${textAlignment}`">{{ name }}</h2>
  </v-container>
</template>

<script>
import AnimationTriggerMixin from "@/mixins/AnimationTriggerMixin";

export default {
  name: "AboutInDepth",
  mixins: [AnimationTriggerMixin],
  props: {
    title: {
      type: String,
      default: "Title",
    },
    description: {
      type: Object,
      default: () => ({
        line1: "Line 1",
        line2: "Line 2",
        line3: "Line 3",
      }),
    },
    name: {
      type: String,
      default: "Name",
    },
    image1: {
      type: String,
      required: true,
    },
    image2: {
      type: String,
      required: true,
    },
    image3: {
      type: String,
      required: true,
    },
    imagesLeft: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    leftAlign() {
      return this.imagesLeft && this.$vuetify.breakpoint.smAndUp;
    },
    textAlignment() {
      return this.leftAlign ? 'text-left': this.$vuetify.breakpoint.xs ? 'text-center' : 'text-right';
    },
    flexAlign() {
      return this.leftAlign ? 'align-start' : this.$vuetify.breakpoint.xs ? 'algin-center' : 'align-end';
    },
    flexJustify() {
      return this.leftAlign ? 'justify-start' : this.$vuetify.breakpoint.xs ? 'justify-center' : 'justify-end';
    },
    flexDirection() {
      return this.leftAlign ? 'flex-row-reverse' : this.$vuetify.breakpoint.xs ? 'flex-column-reverse' : 'flex-row';
    }
  },
  methods: {
    getUrl(imageName) {
      return require(`@/assets/team/${imageName}`);
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/styles.sass";

.team-member-container {
  width: 100%;
  min-height: 200px;
}

.text-content {
  width: 34%;
}

.team-member-title {
  font-size: 32px;
  font-weight: 600;
  line-height: 150%; /* 48px */;
  text-transform: uppercase;
}

.bio-details {
  font-size: 24px;
  font-weight: 400;
  line-height: 150%;
}

.name {
  color: var(--v-primaryAction-base);
  font-family: 'PhosphatePro';
  font-size: 84px;
  font-weight: normal;
  line-height: 150%; /* 126px */
  text-transform: uppercase;
}

.images {
  width: 66%;
}

.team-image {
  flex: 1;
  max-width: 31.25%;
  aspect-ratio: 1;
  overflow: hidden;
  border-radius: 5px;
}

.team-image .v-image {
  border-radius: 5px;
}

.team-image.featured {
  max-width: 37.5%;
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {

  .text-content {
    font-size: 24px;
  }

  .team-member-title {
    font-size: 24px;
  }

  .bio-details {
    font-size: 24px;
  }

  .name {
    font-size: 48px;
  }

  .team-image {
    width: 30%;
  }

  .team-image.featured {
    width: 40%
  }
}

@media #{map-get($display-breakpoints, 'xs-only')} {

  .text-content {
    width: 100%;
  }

  .team-member-title {
    margin-top: 30px;
  }

  .images {
    width: 100%;
  }

  .team-image {
    aspect-ratio: 2;
    width: auto;
    max-width: 80%;
    margin: 0px 52px;
  }

  .team-image.featured {
    max-height: none;
    max-width: 100%;
    width: 100%;
    height: auto;
    margin: 4px 0px;
  }
}
</style>