<template>
  <v-container>
    <v-form ref="form">
      <v-row>
        <v-col cols="8">
          <div class="totals-text">{{ $t('pay.due') }}</div>
        </v-col>
        <v-col cols="4">
          <TextField
              prefix="$"
              v-model="priceFormatted"
              disabled
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8">
          <div class="totals-text">Tax</div>
        </v-col>
        <v-col cols="4">
          <TextField
            disabled
            value="tbd"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="!promoEdit && !promo" class="promo-text" @click="onPromoEdit">
            Add promotion code
        </v-col>
        <v-col v-if="promoEdit && !promo">
          <v-text-field
              placeholder="Add promotion code"
              v-model="form.promo"
              autofocus
              style="font-size: 1.5em;"
              @blur="onPromoBlur"
              :error-messages="errorMessages"
              :error="true"
          >
            <template v-slot:append>
              <v-btn
                  text
                  :loading="promoLoading"
                  @click="onPromoClick"
                  color="primary"
                  style="font-size: 1em"
              >
                apply
                <span slot="loader">
                  <v-progress-circular
                      indeterminate
                      color="primary"
                  />
                </span>
              </v-btn>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="8" v-if="promo">
          <div class="totals-text">
            <div style="float: left;">
              <v-btn @click="onPromoRemove">
                <v-icon left>mdi-tag-outline</v-icon>
                {{ promo.code }}
                <v-icon right>mdi-close</v-icon>
              </v-btn>
              <div style="font-size: .5em; color: grey; text-align: left">{{ promo.name }}</div>
            </div>

            {{ $t('pay.discount') }}
          </div>
        </v-col>
        <v-col cols="4" v-if="promo">
          <TextField
              prefix="$"
              v-model="discountFormatted"
              disabled
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8">
          <div class="totals-text"> {{ $t('pay.total') }}</div>
        </v-col>
        <v-col cols="4">
          <TextField
              prefix="$"
              v-model="totalFormatted"
              disabled
          />
        </v-col>
      </v-row>
      <AlertError :error="error" />
      <v-row>
        <v-col style="text-align: right">
          <v-btn class="btn btn--accent mb-5" color="text" block @click="submit">
            <span class="btn__text btn__text--white">
              {{ $t('pay.checkout') }}
            </span>
          </v-btn>
        </v-col>
      </v-row>
      <div class="totals-text">{{ $t('pay.whenDone') }}</div>
      <div class="totals-text">{{ $t('pay.pagePrice') }}</div>
    </v-form>
  </v-container>
</template>

<script>
import TextField from '@/components/TextField';
import AlertError from '@/components/AlertError';

  export default {
    name: 'PayForm',
    components: {
      TextField,
      AlertError
      // ,StripeElementCard
      // StripeCheckout
    },
    props: {
      jam: {
        type: Object,
        default: () => ({ freq: 0, selTz: 'MST', tod: 1 })
      },
      stripeKey: {
        type: String,
        default: ''
      },
      defaultPaymentType: {
        type: Object
      },
      promoLoading: {
        type: Boolean,
        default: false
      },
      price: {
        type: Number
      },
      promoResult: {
        type: Object,
        default: () => ({})
      }
    },
    data () {
      return {
        form: {...this.jam},
        discount: 0.00,
        total: '27.50',
        cc: '',
        exp: '',
        cvv: '',
        addr: '',
        promo: false,
        error: '',
        promoEdit: false,
        errorMessages: [],
        tokenData: {
          'address_line1': '',
          name: ''
        },
        rules: {
          required: value => !!value || 'Required.',
          cc: [
            v => !!v || 'Credi Card is required',
            v => /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/.test(v) || 'Please enter a valid credit card number.'
          ],
          name: [
            v => !!v || 'Name on credit card is required'
          ],
          exp: [
            v => !!v || 'Expiration date is required'
          ],
          cvv: [
            v => !!v || 'CVV is required'
          ],
          addr: [
            v => !!v || 'Billing address is required'
          ]
        }
      }
    },
    computed: {
      priceFormatted() {
        return (this.price/100).toFixed(2);
      },
      totalFormatted() {
        return ((this.price - this.discount)/100).toFixed(2);
      },
      discountFormatted() {
        return '-' + (this.discount/100).toFixed(2);
      }
    },
    methods: {
      onPromoRemove() {
        this.$emit('promo-remove');
        this.promo = false;
        this.form.promo = '';
        this.discount = 0;
      },
      onPromoBlur() {
        if (this.form && (!this.form.promo || this.form.promo === '')) {
          this.promoEdit = false;
        }
      },
      onPromoEdit() {
        this.promoEdit = true;
      },
      onPromoClick() {
        // console.log(this.form);
        this.errorMessages = [];
        this.$emit('promo', this.form.promo);
      },
      submit() {
        this.$emit('checkout');
        // if (this.defaultPaymentType.id) {
        //   // this.form.paymentTypeId = this.defaultPaymentType.id;
        //   // this.form.itemId = 'defaultBook';
        //   this.$emit('save', this.form);
        // } else {
        //   if (this.$refs.form.validate()) {
        //     this.$refs.cardElement.submit();
        //   } else {
        //     console.log('not validated');
        //   }
        // }
      },
      // tokenCreated(token) {
      //   // card token created
      //   // send api request to charge card.
      //   // this.$emit('save', {...token});
      //   this.form.token = token;
      //   this.form.itemId = 'defaultBook';
      //   this.$emit('save', this.form);
      //   // console.log(this.form);
      //   // console.log(token);
      // },
      handleApplyPromo() {
        // this will emit a promo-apply event
      }
    },
    watch: {
      jam: function(newVal) {
        this.form = {...newVal};
      },
      promoResult: function(newVal) {
        if (newVal.error) {
          // this.$refs.promoCode.error = true;
          this.errorMessages.push(newVal.error);
          this.promoError = true;
        } else {
          this.promo = newVal;
          if (this.promo.amount_off) {
            this.discount = this.promo.amount_off;
          }
          if (this.promo.percent_off) {
            this.discount = (this.price * this.promo.percent_off) / 100
          }
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .totals-text {
    text-align: right;
    font-size: 2rem;
  }
  .promo-text {
    text-align: right;
    font-size: 2rem;
    color: var(--v-secondary-base);
    cursor: pointer;
  }
</style>
