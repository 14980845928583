<template>
  <v-container>
    <v-row v-intersect.once="onIntersect" class="d-flex justify-center">
      <v-col class="d-flex flex-column align-center">
        <h1 class="avatars-header__text">{{ $t('homePage.waysToJam') }}</h1>
        <h3 class="text-center" v-html="$t('homePage.infinityWays')"></h3>
      </v-col>
    </v-row>
    <v-row class="d-flex avatars justify-center">
      <v-col class="d-flex justify-center animation-min-height" sm="3" cols="12">
        <v-scale-transition origin="center">
          <Avatar
            v-if="showAvatarOne"
            class="d-flex justify-center"
            :avatar="avatars.luggageAvatar"
            :size="avatarSize"
            :commonAvatar="avatars.commonAvatarProperties"
          />
        </v-scale-transition>
      </v-col>
      <v-col class="d-flex justify-center animation-min-height" sm="3" cols="12">
        <v-scale-transition origin="center">
          <Avatar
            v-if="showAvatarTwo"
            class="d-flex justify-center"
            :avatar="avatars.notebookAvatar"
            :size="avatarSize"
            :commonAvatar="avatars.commonAvatarProperties"
          />
        </v-scale-transition>
      </v-col>
      <v-col class="d-flex justify-center animation-min-height" sm="3" cols="12">
        <v-scale-transition origin="center">
          <Avatar
            v-if="showAvatarThree"
            class="d-flex justify-center"
            :avatar="avatars.partyAvatar"
            :size="avatarSize"
            :commonAvatar="avatars.commonAvatarProperties"
          />
          </v-scale-transition>
      </v-col>
      <v-col class="d-flex justify-center animation-min-height" sm="3" cols="12">
        <v-scale-transition origin="center">
          <Avatar
            v-if="showAvatarFour"
            class="d-flex justify-center"
            :avatar="avatars.crownAvatar"
            :size="avatarSize"
            :commonAvatar="avatars.commonAvatarProperties"
          />
        </v-scale-transition>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center mb-15">
      <v-col lg="6" cols="10">
        <v-scroll-y-reverse-transition>
          <v-btn v-if="showButton" class="btn btn--primary" color="primaryAction" block depressed @click="$router.push('/ways')">
            <span class="btn__text btn__text--white">{{ $t('homePage.more') }}</span>
          </v-btn>
        </v-scroll-y-reverse-transition>
      </v-col>
    </v-row>
    <v-row v-intersect.once="onIntersectEnd">
    </v-row>
    <LifeMovesFast/>
    <About/>
  </v-container>
</template>

<script>
import Avatar from '@/components/Avatar';
import About from '@/components/About';
import LifeMovesFast from '@/components/LifeMovesFast';

  export default {
    name: 'WaysToJam',
    components: {
      Avatar,
      About,
      LifeMovesFast,
    },
    computed: {
      avatarSize() {
        if (this.$vuetify.breakpoint.lgAndUp) {
          return 235
        } else {
          return 151
        }
      }
    },
    data() {
      return {
        showAvatarOne: false,
        showAvatarTwo: false,
        showAvatarThree: false,
        showAvatarFour: false,
        showButton: false,
        avatars: {
          commonAvatarProperties: {
            hoverDisabled: false,
          },
          luggageAvatar: {
            color: "accentBackground",
            text: this.$t('homePage.travel'),
            image: require('@/assets/Luggage.svg'),
          },
          notebookAvatar: {
            color: "primaryAction",
            text: this.$t('homePage.journal'),
            image: require('@/assets/Notebook.svg')
          },
          partyAvatar: {
            color: "secondaryBackground",
            text: this.$t('homePage.specialEvent'),
            image: require('@/assets/Party.svg'),
          },
          crownAvatar: {
            color: "accentBackground",
            text: this.$t('homePage.specialPerson'),
            image: require('@/assets/Crown.svg'),
          },
        },
      }
    },
    methods: {
      onIntersect(entries) {
        let vm = this
        setTimeout(function() {
          vm.showAvatarOne = entries[0].isIntersecting;
        }, 400);
        setTimeout(function() {
          vm.showAvatarTwo = entries[0].isIntersecting;
        }, 500);
        setTimeout(function() {
          vm.showAvatarThree = entries[0].isIntersecting;
        }, 600);
        setTimeout(function() {
          vm.showAvatarFour = entries[0].isIntersecting;
        }, 700);
      },
      onIntersectEnd(entries) {
        this.showButton = entries[0].isIntersecting;
      }
    }
  }
</script>

<style scoped>
  .animation-min-height {
    width: 235px;
    height: 235px;
  }
  .avatars-header__text {
    font-size: 10rem;
    text-align: center;
    line-height: 100%
  }
  .avatars {
    margin-bottom: 5rem;
  }
  @media only screen and (max-width: 960px) {
    .avatars-header__text {
      font-size: 5rem;
    }
    h3 {
      font-size: 2rem;
    }
  }
</style>
