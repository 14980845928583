<template>
  <v-container
    class="inspiration-container mx-auto"
    fluid
  >
    <div v-intersect.once="entries => onIntersect(entries, 'showTitle')">
      <v-slide-x-transition>
        <h1 v-if="showTitle" class="header__title text-center">{{ $t("pricingPage.secondaryTitle") }}</h1>
      </v-slide-x-transition>
    </div>
    <v-row :dense="$vuetify.breakpoint.smAndDown">
      <v-col v-intersect.once="entries => onIntersect(entries, 'showImage1')" class="col-12">
        <v-scale-transition>
          <v-img
            v-if="showImage1"
            src="../assets/inspiration_1.jpeg"
            class="inspiration-image"
            aspect-ratio="1.67"
          />
        </v-scale-transition>
      </v-col>
      <v-col v-intersect.once="entries => onIntersect(entries, 'showImage2')" class="col-6">
        <v-scale-transition>
          <v-img
            v-if="showImage2"
            src="../assets/inspiration_2.jpeg"
            class="inspiration-image"
            aspect-ratio="1.33"
            position="0% 75%"
          />
        </v-scale-transition>
      </v-col>
      <v-col v-intersect.once="entries => onIntersect(entries, 'showImage3')" class="col-6">
        <v-scale-transition>
          <v-img
            v-if="showImage3"
            src="../assets/inspiration_3.jpeg"
            class="inspiration-image"
            aspect-ratio="1.33"
            position="0% 90%"
          />
        </v-scale-transition>
      </v-col>
      <v-col v-intersect.once="entries => onIntersect(entries, 'showImage4')" class="col-12">
        <v-scale-transition>
          <v-img
            v-if="showImage4"
            src="../assets/inspiration_4.jpeg"
            class="inspiration-image"
            aspect-ratio="1.67"
          />
        </v-scale-transition>
      </v-col>
      <v-col v-intersect.once="entries => onIntersect(entries, 'showImage5')" class="col-12">
        <v-scale-transition>
          <v-img
            v-if="showImage5"
            src="../assets/inspiration_5.jpeg"
            class="inspiration-image"
            aspect-ratio="1.67"
          />
        </v-scale-transition>
      </v-col>
      <v-col v-intersect.once="entries => onIntersect(entries, 'showImage6')" class="col-12">
        <v-scale-transition>
          <v-img
            v-if="showImage6"
            src="../assets/inspiration_6.jpeg"
            class="inspiration-image"
            aspect-ratio="2.69"
            position="0% 75%"
          />
        </v-scale-transition>
      </v-col>
      <v-col v-intersect.once="entries => onIntersect(entries, 'showImage7')" class="col-12">
        <v-scale-transition>
          <v-img
            v-if="showImage7"
            src="../assets/inspiration_7.jpeg"
            class="inspiration-image"
          />
        </v-scale-transition>
      </v-col>
      <v-col v-intersect.once="entries => onIntersect(entries, 'showImage8')" class="col-12">
        <v-scale-transition>
          <v-img
            v-if="showImage8"
            src="../assets/inspiration_8.jpeg"
            class="inspiration-image"
            aspect-ratio="1.67"
          />
        </v-scale-transition>
      </v-col>
      <v-col v-intersect.once="entries => onIntersect(entries, 'showImage9')" class="col-12">
        <v-scale-transition>
          <v-img
            v-if="showImage9"
            src="../assets/inspiration_9.jpeg"
            class="inspiration-image"
            aspect-ratio="2.69"
            position="0% 65%"
          />
        </v-scale-transition>
      </v-col>
      <v-col v-intersect.once="entries => onIntersect(entries, 'showImage10')" class="col-12">
        <v-scale-transition>
          <v-img
            v-if="showImage10"
            src="../assets/inspiration_10.jpeg"
            class="inspiration-image"
            aspect-ratio="2.11"
            position="bottom"
          />
        </v-scale-transition>
      </v-col>
      <v-col v-intersect.once="entries => onIntersect(entries, 'showImage11')" class="col-12">
        <v-scale-transition>
          <v-img
            v-if="showImage11"
            src="../assets/inspiration_11.jpeg"
            class="inspiration-image"
            aspect-ratio="2.69"
            position="0% 45%"
          />
        </v-scale-transition>
      </v-col>
    </v-row>
    <div
      v-intersect.once="entries => onIntersect(entries, 'showButton')"
      class="button-container d-flex align-center"
    >
      <v-slide-x-transition>
        <v-btn
          v-if="showButton"
          depressed
          class="btn btn-img btn--pattern mx-auto"
          :block="$vuetify.breakpoint.xs"
          :to="{ path: '/signup' }"
        >
          <span class="btn__text btn__text--white">{{ $t('pricingPage.startPreserving') }}</span>
        </v-btn>
      </v-slide-x-transition>
    </div>
  </v-container>
</template>

<script>
import AnimationTriggerMixin from '@/mixins/AnimationTriggerMixin';
export default {
  name: "Inspiration",
  mixins: [AnimationTriggerMixin],
  data() {
    return {
      showTitle: false,
      showImage1: false,
      showImage2: false,
      showImage3: false,
      showImage4: false,
      showImage5: false,
      showImage6: false,
      showImage7: false,
      showImage8: false,
      showImage9: false,
      showImage10: false,
      showImage11: false,
      showButton: false,
    };
  },
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

.inspiration-container {
  padding: 80px;
  max-width: 1440px;
}

.header__title {
  margin: 80px auto 150px;
}

.button-container {
  margin-top: 180px;
}

.btn-img {
  background-image: url(../assets/Sign_Up_Button.svg);
  background-position: center;
  background-size: 100% 100%;
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .inspiration-container {
    padding: 70px;
  }

  .header__title {
    margin-top: 70px;
  }
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .inspiration-container {
    padding: 35px;
  }

  .header__title {
    font-size: 48px;
    margin: 50px auto 80px;
  }

  .button-container {
    margin-top: 80px;
  }
}
</style>