<template>
  <v-footer
    color="text"
    bottom
  >
    <v-container>
      <v-row class="d-flex footer">
        <v-col lg="2" sm="3" cols="12" class="d-flex flex-column py-0">
          <router-link
              v-for="link in firstLinks"
              :key="link.title"
              :to="link.route">
            <span
              color="white"
              class="footer__text"
              text
            >
              {{ link.title }}
            </span>
          </router-link>
        </v-col>
        <v-col lg="2" sm="3" cols="12" class="d-flex flex-column py-0">
          <router-link
              v-for="link in secondLinks"
              :key="link.title"
              :to="link.route"
          >
            <span
              color="white"
              class="footer__text"
              text
              :to="link.route"
            >
              {{ link.title }}
            </span>
          </router-link>
          <a
              style="color: #F5F5F5"
              class="footer__text" href="https://jambooks.zendesk.com/hc/en-us/sections/15542960446740-FAQ"
              target="_blank"
          >FAQ</a>
        </v-col>
        <v-col lg="8" sm="6" cols="12" class="d-flex icon-wrapper py-0">
          <div class="d-flex flex-column icon-container">
            <span class="footer__text icon__text">{{ $t('footer.followUs') }}</span>
            <v-row class="mt-3">
              <v-btn class="icon" icon @click="follow('fb')">
                <img :src="facebook"/>
              </v-btn>
              <v-btn class="icon" icon @click="follow('ig')">
                <img :src="instagram"/>
              </v-btn>
              <v-btn icon @click="follow('yt')">
                <img :src="youtube"/>
              </v-btn>
            </v-row>
          </div>
        </v-col>
        <v-col class="d-flex justify-center" cols="12">
          <img :src="jambooksLogo"/>
        </v-col>
        <v-col class="text-center d-flex justify-center">
          <router-link to="/"><span class="footer__text footer__text--light d-none d-lg-flex mr-5">{{ $t('footer.terms') }}</span></router-link>
          <router-link to="/"><span class="footer__text footer__text--light copy-right__text mr-5">{{ $t('footer.copyright') }}</span></router-link>
          <router-link to="/privacy"><span class="footer__text footer__text--light copy-right__text mr-5">{{ $t('footer.privacyPolicy') }}</span></router-link>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: 'Footer',

  data() {
    return {
      firstLinks: [
        { title: this.$t('footer.aboutUs'), route: '/about' },
        // { title: this.$t('footer.community'), route: '/' },
        // { title: this.$t('footer.contact'), route: '/'}
      ],
      secondLinks: [
        { title: this.$t('footer.products'), route: '/products' },
        { title: this.$t('footer.pricing'), route: '/products'},
        // { title: this.$t('footer.faq'), route: '/' }
      ],
    }
  },
  computed: {
    jambooksLogo() {
      return require('@/assets/Jambooks_Outline.svg')
    },
    facebook() {
      return require('@/assets/fb.svg')
    },
    instagram() {
      return require('@/assets/insta.svg')
    },
    youtube() {
      return require('@/assets/youtube.svg')
    }
  },
  methods: {
    follow(svc) {
      let url = '';
      switch(svc) {
        case 'fb':
          url = 'https://www.facebook.com/profile.php?id=100094190176085&mibextid=LQQJ4d';
          break;
        case 'ig':
          url = 'https://instagram.com/jambooks.co?igshid=OGQ5ZDc2ODk2ZA==';
          break;
        case 'yt':
          url = 'https://youtube.com/@Jambooks';
          break;
      }
      window.open(url, '_blank');
    }
  }
}
</script>

<style>
  a { text-decoration: none; }
  .footer {
    margin-top: 4.5rem;
  }
  .footer__text {
    font-weight: bold;
    font-size: 2.4rem;
    text-transform: uppercase;
    color: var(--v-footerText-base);
  }
  .footer__text--light {
    font-weight: 300;
    font-size: 2rem;
  }
  .icon-wrapper {
    justify-content: flex-end;
  }
  .icon {
    margin-right: 3rem;
  }

  @media only screen and (max-width: 600px) {
    .icon__text {
      text-align: center;
    }
    .icon-wrapper {
      justify-content: center;
    }
    .icon-container {
      justify-content: center;
    }
    .copy-right__text {
      font-size: 1.25rem;
    }
  }
</style>


