<template>
  <v-container>
    <div style="display: block; text-align: center;">
      <h5 class="header__title_bold">Prompts</h5>
    </div>
    <v-row class="d-flex flex-column">
      <v-col class="d-flex justify-center">
        <span class="header__text header-span">
          View and edit your prompts and when they are sent.
        </span>
      </v-col>
    </v-row>
    <v-row class="d-flex align-center" v-if="jam && jam.typeObj && jam.typeObj.group === 'person'">
      <v-col>
        <TextField
            label="VIP Name"
            placeholder="VIP Name"
            v-model="model.vipName"
            @change="update()"
        />
      </v-col>
    </v-row>
    <v-row class="d-flex align-center">
      <v-col lg="4" md="6" cols="12">
        <v-select
            label="Time Zone"
            :items="zones"
            v-model="model.tz"
            color="secondary"
            @change="update()"
        />
      </v-col>
      <v-col lg="3" md="6" cols="12">
        <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="model.startDate"
                label="Start Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                color="secondary"
                @change="update()"
            />
          </template>
          <v-date-picker
              v-model="model.startDate"
              @input="menu2 = false"
              @change="update()"
              color="secondary"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col lg="3" md="6" cols="12">
        <v-menu
            v-model="menuEnd"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
            :disabled="model.neverEnds"
            color="secondary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="model.endDate"
                label="End Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                :disabled="model.neverEnds"
                color="secondary"
            />
          </template>
          <v-date-picker
              v-model="model.endDate"
              @input="menuEnd = false"
              @change="update()"
              color="secondary"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col lg="2" md="6" cols="12">
        <v-switch
            :label="$t('when.noEnd')"
            v-model="model.neverEnds"
            color="secondary"
            @change="update()"
        />
      </v-col>
    </v-row>
    <v-row class="d-flex align-center" v-for="(prompt, k) in tzPrompts()" :key="k">
      <v-col lg="3" md="3" cols="12">
          <v-icon left>mdi-calendar</v-icon>
          {{ prompt.date }} @ {{ prompt.time }}
      </v-col>
      <v-col lg="6" md="6" cols="12">{{ prompt.text }}</v-col>
      <v-col lg="3" md="3" cols="">
        <v-btn @click="handleEdit(k)">
          <v-icon left v-if="prompt.locked">mdi-lock-outline</v-icon>
          <v-icon left v-else>mdi-pencil</v-icon>
          Edit
        </v-btn>
        <v-btn class="ml-1" @click="handlePromptPreview(k)">
          <v-icon left>mdi-eye-outline</v-icon>
          View
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn @click="handleAddPrompt">
          <v-icon left>mdi-plus</v-icon>
          Add Prompt
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="pt-10" v-if="jamStatus !== 'active'">
      <v-btn block x-large color="primary" @click="confirmPrompts">Confirm prompts and activate Jam</v-btn>
    </v-row>
    <v-dialog
      v-model="editDialog"
      max-width="650"
      @click:outside="handleCancelEdit"
    >
      <v-card>
        <v-card-title class="text-h5">
          Edit Prompt
        </v-card-title>

        <v-card-text>
          <v-row justify="space-around"
          align="center">
            <v-col style="width: 300px; flex: 0 1 auto;">
              <span>Date</span>
              <v-date-picker
                  v-model="datePicker"
                  class="pr-4"
                  color="secondary"
              ></v-date-picker>
            </v-col>
            <v-col style="width: 300px; flex: 0 1 auto;">
              <span>Time</span>
              <v-time-picker
                  v-model="timePicker"
                  ampm-in-title
                  color="secondary lighter-2"
              ></v-time-picker>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <TextField
              placeholder="Prompt"
              label="Prompt"
              v-model="selected.text"
          />
        </v-card-text>
        <v-card-actions>
          <AlertError :error="errorNewMsg" />
          <v-spacer/>
          <v-btn
              color="darken-1"
              text
              @click="handleCancelEdit"
          >Cancel</v-btn>
          <v-btn
              color="primary"
              @click="handleDialogSave"
          >Save</v-btn>
          <v-btn
              v-if="!newAdded && !selected.locked"
              color="red"
              class="text--white"
              @click="confirmDeleteDialog = true">
            <v-icon color="#ffffff">mdi-delete</v-icon>
            <span style="color: #fff">Delete</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="confirmDeleteDialog"
        width="450"
    >
      <ConfirmDialog
          title="Are you sure you want to delete this prompt?"
          @confirm="handleDeletePrompt"
          @cancel="confirmDeleteDialog = false"
      />
    </v-dialog>
    <v-dialog
        v-model="previewDialog"
        max-width="450"
    >
      <v-card>
        <v-card-title class="text-h5">
          Preview Prompt
        </v-card-title>
        <v-card-text>
          <PhoneTop
              :message="message"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
              color="darken-1"
              text
              @click="previewDialog = false"
          >OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="editLockedDialog"
        width="450"
    >
      <AlertDialog
          title="You cannot edit or delete a prompt that has already been sent."
          @confirm="handleDeletePrompt"
          @cancel="editLockedDialog = false"
      />
    </v-dialog>
  </v-container>
</template>
<script>
import { timeZones } from '@/lib/';
import TextField from '@/components/TextField'
import Http from '@/api/Http';
import AlertError from '@/components/AlertError';
import ConfirmDialog from '@/components/dialogs/Confirm';
import AlertDialog from '@/components/dialogs/Alert';
import PhoneTop from '@/components/PhoneTop';

export default {
  name: 'JamPromptsEdit',
  components: {
    AlertDialog,
    AlertError,
    ConfirmDialog,
    PhoneTop,
    TextField
  },
  props: {
    jam: {
      type: Object
    }
  },
  data() {
    return {
      previewDialog: false,
      menu2: false,
      menuEnd: false,
      api: Http.use('data'),
      prompts: [],
      editDialog: false,
      datePicker: '',
      timePicker: '',
      errorNewMsg: '',
      errorMsg: '',
      newAdded: false,
      message: '',
      confirmDeleteDialog: false,
      editLockedDialog: false,
      jamStatus: '',
      selected: {
        date: '',
        time: '',
        prompts: ''
      },
      model: {
        endDate: '',
        neverEnds: false,
        prompts: [],
        tz: '',
        startDate: '',
        vipName: ''
      },
      selectedId: null,
      abvToTimestamp: {
        PNT: 'America/Phoenix',
        PST: 'America/Los_Angeles',
        MST: 'America/Denver',
        CST: 'America/Chicago',
        EST: 'America/New_York',
        HST: 'Pacific/Honolulu'
      }
    }
  },
  computed: {
    zones () {
      let ret = [];
      for(let item in timeZones) {
        ret.push({text: this.$t(timeZones[item]), value: item});
      }
      return ret;
    }
  },
  created() {
    this.index();
  },
  methods: {
    getEndDateUtc() {
      const [year, month, day] = this.model.endDate.split('-');
      const tzDt = this.createDateInTimezone(this.model.tz, year, month, day, 23, 59, 59);
      return this.convertToUTC(tzDt);
    },
    getStartDateUtc() {
      const [year, month, day] = this.model.startDate.split('-');
      const tzDt = this.createDateInTimezone(this.model.tz, year, month, day, '00', '00', '00');
      return this.convertToUTC(tzDt);
    },
    createDateInTimezone(timezone, year, month, day, hours, minutes, seconds) {
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZone: timezone,
      };

      const dateFormatter = new Intl.DateTimeFormat('en-US', options);
      const dateString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
      const localDate = new Date(dateString);
      return new Date(dateFormatter.format(localDate));
    },
    convertToUTC(date) {
      const utcString = date.toISOString();
      return new Date(utcString);
    },
    confirmPrompts() {
      this.api.patch(`/jams/${this.$route.params.id}`, {
        status: 'active'
      })
          .then(() => {
            this.$router.push(`/jam/${this.$route.params.id}/jammers`);
            this.$emit('notify', {
              visible: true,
              color: 'success',
              text: 'Prompts confirmed and jam activated',
              icon: 'mdi-check-circle'
            });
          })
          .catch(err => {
            this.$emit('notify', {
              visible: true,
              color: 'red accent-2',
              text: err.message,
              icon: 'mdi-alert-circle-outline'
            });
          });
    },
    tzPrompts() {
      return this.model.prompts.map(pt => {
        const utcDate = new Date(pt.ts);
        let dt = new Intl.DateTimeFormat('en-US', {
          timeZone: this.model.tz,
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }).format(utcDate);
        let tm = new Intl.DateTimeFormat('en-US', {
          timeZone: this.model.tz,
          hour: 'numeric',
          minute: '2-digit'
        }).format(utcDate);
        return {
          text: pt.text,
          date: dt,
          time: tm,
          locked: pt.locked
        }
      });
    },
    handlePromptPreview(index) {
      this.message = this.model.prompts[index].text.replace('{VIP_NAME}', this.model.vipName);
      this.previewDialog = true;
    },
    update() {
      this.errorMsg = '';
      const stDt = this.getStartDateUtc();
      const endDt = this.getEndDateUtc();
      if (!this.model.neverEnds && endDt.getTime() < stDt.getTime()) {
        this.errorMsg = 'The Jam end date cannot be before the Jam start date.';
      }
      if (this.errorMsg === '') {
        for (const p in this.model.prompts) {
          if (this.model.prompts[p].text === '') {
            this.errorMsg = 'Prompt text cannot be left blank.'
            break;
          }
          const ptDt = new Date(this.model.prompts[p].ts);
          if (ptDt.getTime() < stDt.getTime()) {
            this.errorMsg = 'Prompt date cannot be before the Jam start date';
            break;
          }
          if (!this.model.neverEnds && ptDt.getTime() > endDt.getTime()) {
            this.errorMsg = 'Prompt date cannot be after the Jam end date';
            break;
          }
        }
      }
      if (this.errorMsg === '') {
        this.api.put(`/jams/${this.$route.params.id}/update`, this.model)
            .then(() => {
              this.$emit('notify', {
                visible: true,
                color: 'success',
                text: 'Jam updated',
                icon: 'mdi-check-circle'
              });
            })
            .catch(err => {
              this.$emit('notify', {
                visible: true,
                color: 'red accent-2',
                text: err.message,
                icon: 'mdi-alert-circle-outline'
              });
            });
      } else {
        this.$emit('notify', {
          visible: true,
          color: 'red accent-2',
          text: this.errorMsg,
          icon: 'mdi-alert-circle-outline'
        });
      }
    },
    index () {
      if (this.$route.query.act === 'last') {
        this.handleAddLastPrompt();
      } else if (this.$route.query.act === 'new') {
        this.handleAddPrompt();
      }
      this.api.get(`/jams/${this.$route.params.id}/prompts`)
          .then(jam => {
            this.model.prompts = jam.prompts;
            this.jamStatus = jam.status;
          })
          .catch(err => {
            this.$emit('notify', {
              visible: true,
              color: 'red accent-2',
              text: err.message,
              icon: 'mdi-alert-circle-outline'
            });
          });
    },
    getTimezoneDate(ts, tz) {
      const dtEng = new Date(ts).toLocaleString('en-US', {
        timeZone: tz
      });
      return new Date(dtEng);
    },
    getDate(ts) {
      const dt = this.getTimezoneDate(ts, this.model.tz);
      return (dt.getMonth() + 1) + '/' + dt.getDate() + '/' + dt.getFullYear();
    },
    getTime (ts) {
      const dt = this.getTimezoneDate(ts, this.model.tz);
      const am = dt.getHours() < 12;
      const hrs = dt.getHours() % 12;
      const mins = (dt.getUTCMinutes() < 10) ? '0' + dt.getUTCMinutes() : dt.getUTCMinutes();
      return hrs + ':' + mins + ' ' + (am ? 'AM': 'PM');
    },
    handleEdit (evt) {
      this.selectedId = evt;
      this.selected = this.model.prompts[evt];
      // if (this.selected.locked) {
      //   this.editLockedDialog = true;
      // } else {
        const dt = this.getTimezoneDate(this.selected.ts, this.model.tz);
        this.datePicker = dt.getFullYear() + '-' + ('0' + (dt.getMonth()+1)).slice(-2) + '-' + dt.getDate();
        this.timePicker = dt.getHours() + ':' + ((dt.getMinutes() < 10) ? '0' + dt.getMinutes() : dt.getMinutes());
        this.errorNewMsg = '';
        this.editDialog = true;
      // }
    },
    dateWithTimeZone (timeZone, year, month, day, hour, minute, second) {
      let date = new Date(Date.UTC(year, month, day, hour, minute, second));
      let utcDate = new Date(date.toLocaleString('en-US', { timeZone: "UTC" }));
      let tzDate = new Date(date.toLocaleString('en-US', { timeZone: timeZone }));
      let offset = utcDate.getTime() - tzDate.getTime();
      date.setTime( date.getTime() + offset );
      return date;
    },
    handleDialogSave() {
      const jamStartDt = this.getStartDateUtc();
      const jamEndDt = this.getEndDateUtc();
      let yr, mth, dy, hr, min;
      [yr, mth, dy] = this.datePicker.split('-');
      [hr, min] = this.timePicker.split(':');
      mth = parseInt(mth) - 1;
      const ptDt = this.dateWithTimeZone(this.model.tz, yr, mth, dy, hr, min, 0);
      if (this.selected.text === '') {
        this.errorNewMsg = 'Prompt text cannot be left blank.';
      } else if (ptDt.getTime() < jamStartDt.getTime()) {
        this.errorNewMsg = 'Prompt date cannot be before the Jam start date';
      } else if (ptDt.getTime() < (new Date()).getTime()) {
        this.errorNewMsg = 'Prompts cannot be set in the past.';
      } else if (!this.model.neverEnds && ptDt.getTime() > jamEndDt.getTime()) {
        this.errorNewMsg = 'Prompt data cannot be after the Jam end date';
      } else {
        this.errorNewMsg = '';
        this.selected.ts = ptDt;
        this.sortPrompts();
        this.newAdded = false;
        this.editDialog = false;
        this.update();
      }
    },
    sortPrompts() {
      this.model.prompts.sort((a, b) => {
        const fst = new Date(a.ts);
        const snd = new Date(b.ts);
        return (fst > snd) ? 1 : -1;
      });
    },
    handleAddPrompt() {
      this.model.prompts.push({ts: (new Date()), text: ''});
      this.handleEdit(this.model.prompts.length - 1);
      this.newAdded = true;
    },
    handleAddLastPrompt() {
      this.model.prompts.push({ts: (new Date()), text: 'This is your last chance to add anything to the jam.'});
      this.handleEdit(this.model.prompts.length - 1);
      this.newAdded = true;
    },
    handleCancelEdit() {
      if (this.newAdded) {
        //this.model.prompts.pop();
        this.model.prompts.pop();
        this.newAdded = false;
      }
      this.editDialog = false;
    },
    handleDeletePrompt () {
      this.selected = {
        date: '',
        time: '',
        prompts: ''
      };
      this.model.prompts.splice(this.selectedId, 1);
      this.selectedId = null;
      this.confirmDeleteDialog = false;
      this.editDialog = false;
      this.update();
    }
  },
  watch: {
    jam: {
      immediate: true,
      handler: function(jamFromProps) {
        if (jamFromProps) {
          this.model = {
            endDate: jamFromProps.endDate,
            neverEnds: jamFromProps.neverEnds,
            prompts: [],
            tz: jamFromProps.tz,
            startDate: jamFromProps.startDate,
            vipName: jamFromProps.vipName
          }
        }
      }
    }
  }
}
</script>

<style scoped>
.header__text {
  font-size: 2.5rem;
  color: var(--v-primary-base);
  text-align: left;
}
</style>
