<template>
  <v-container class="px-0 pb-0" fluid>
    <v-container>
      <h2 class="header header-span mb-8">{{ $t('pay.header') }}</h2>
      <p class="card-text text-center">
        {{ $t('pay.tag') }}
      </p>
      <v-row>
        <v-col cols="12" md="5">
          <PayCart v-if="form"
              :type="form.type"
              :start="form.startDate"
              :end="form.endDate"
              :freq="$t(frequency[form.freq])"
              :typeName="typeName"
          />
        </v-col>
        <v-col cols="12" md="7">
          <PayForm
              :jam="form"
              @checkout="onCheckout"
              @promo="onPromo"
              :price="price"
              :defaultPaymentType="defaultPaymentType"
              :promoLoading="promoLoading"
              :promoResult="promoResult"
              @promo-remove="$emit('promo-remove')"
          />
        </v-col>
      </v-row>
      <h1 class="text-center pt-7" style="font-size: 4em;">{{ $t('pay.care') }}</h1>
      <p class="text-center font-weight-bold" style="font-size: 3em;">{{ $t('pay.coming') }}</p>
    </v-container>
    <HomeArm />
    <PayPricing />
  </v-container>
</template>

<script>
  import PayCart from '@/components/PayCart';
  import PayForm from '@/components/PayForm';
  import HomeArm from '@/components/HomeArm';
  import PayPricing from '@/components/PayPricing';
  // import { getterNames } from '@/store/typesStore'
  import { frequency, timeZones} from '@/lib';

  export default {
    name: 'Pay',
    components: {
      PayCart,
      PayForm,
      HomeArm,
      PayPricing
    },
    props: {
      jam: {
        type: Object,
        default: () => ({ cpt: 0, cc: '', exp: '', cvv: '', addr: '', promo: '', type: 1 })
      },
      stripeKey: {
        type: String,
        default: ''
      },
      typeName: {
        type: String,
        default: ''
      },
      defaultPaymentType: {
        type: Object
      },
      promoLoading: {
        type: Boolean,
        default: false
      },
      price: {
        type: Number
      },
      promoResult: {
        type: Object
      }
    },
    data() {
      return {
        form: {...this.jam},
        frequency: frequency,
        timeZones: timeZones
      }
    },
    computed: {

    },
    methods: {
      onCheckout() {
        this.$emit('checkout');
      },
      onPromo(val) {
        console.log(val);
        this.$emit('promo', val);
      }
    },
    watch: {
      jam: function(newVal) {
        this.form = {...newVal};
      }
    }
  }
</script>

<style scoped>
.header {
  /*color: var(--v-secondary-base);*/
  text-align: center;
  line-height: 100%;
}

</style>
