<template>
  <v-container>
    <v-row class="d-flex justify-center">
      <v-col class="d-none ma-0 d-md-flex pa-1" cols="0" md="4">
        <v-img :aspect-ratio="aspectRatio" :src="bookStackImage"></v-img>
      </v-col>
      <v-col cols="6" md="4" class="pa-1 justify-center align-center text-center">
        <v-img  :aspect-ratio="aspectRatio" :src="bookPosterImage"></v-img>
      </v-col>
      <v-col class="d-none d-md-flex pa-1" cols="0" md="4">
        <v-img  :aspect-ratio="aspectRatio"  :src="bookFanImage"></v-img>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col class="d-flex flex-column pb-10" lg="4" cols="10">
        <SignupText />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex justify-center">
        <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/JeCGGDBL2iY"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen>
        </iframe>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SignupText from '@/components/SignupText.vue';

export default {
  name: "HomeIntro",
  components: {
    SignupText
  },
  data() {
    return {};
  },
  methods: {
  },
  computed: {
    aspectRatio: () => 0.85,
    youtubeImage() {
      return require("@/assets/Youtube_Image.svg");
    },
    bookFanImage() {
      return require("@/assets/Home/bookfan.png");
    },
    bookPosterImage() {
      return require("@/assets/Home/bookposter.jpg");
    },
    bookStackImage() {
      return require("@/assets/Home/bookstack.png");
    },
  },
};
</script>

<style scoped>
.header__text {
  text-align: center;
  font-size: 5rem;
  line-height: 100%;
}
.header__text--large {
  font-size: 8rem;
}
.v-input >>> input::placeholder {
  text-align: center;
}
.input__field {
  border: 4px solid var(--v-text-base);
  border-radius: 10px;
}
.btn {
  background: url(../assets/Start_Preserving_Background.svg);
  background-size: cover;
}
@media only screen and (max-width: 600px) {
  .header__text {
    font-size: 2.5rem;
  }
  .header_text--large {
    font-size: 4rem;
  }
  img {
    height: auto;
    width: 20rem;
  }
}
@media only screen and (min-width: 601px) and (max-width: 1264px) {
  img {
    width: 36rem;
    height: auto;
  }
}
</style>