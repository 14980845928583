<template>
  <v-card>
    <v-card-title class="text-h7">
      {{ title }}
    </v-card-title>
    <v-card-subtitle>
      {{ subtitle }}
    </v-card-subtitle>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
          color="darken-1"
          text
          @click="$emit('cancel')"
      >
        Cancel
      </v-btn>
      <v-btn
          color="primary"
          @click="$emit('confirm')"
          :loading="loading"
      >Delete
        <span slot="loader">
          <v-progress-circular
              indeterminate
              color="primary"
          />
        </span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: ''
      },
      subtitle: {
        type: String,
        default: ''
      },
      loading: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {}
    }
  }
</script>