<template>
  <v-container>
    <v-row class="d-flex flex-column">
      <v-col class="d-flex justify-center">
        <h3 class="fancy-font text-center">{{ $t('homePage.weGetIt') }}</h3>
      </v-col>
      <v-col class="d-flex justify-center">
        <img class="logo" :src="jambooksLogo"/>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center align-center image-container animation-min-height">
      <v-col lg="3" cols="12" class="d-flex justify-center">
        <v-scale-transition origin="center">
          <img v-show="showBridge" :src="bridge"/>
        </v-scale-transition>
      </v-col>
      <v-col lg="1" cols="12" class="d-flex justify-center">
        <v-scale-transition origin="center">
          <span v-show="showPlus" class="symbol fancy-font">+</span>
        </v-scale-transition>
      </v-col>
      <v-col lg="3" cols="12" class="d-flex justify-center">
        <v-scale-transition origin="center">
          <img v-show="showPhone" :src="handsPhone"/>
        </v-scale-transition>
      </v-col>
      <v-col lg="2" cols="12" class="d-flex justify-center">
        <v-scale-transition origin="center">
          <span v-show="showEqual" class="symbol fancy-font">=</span>
        </v-scale-transition>
      </v-col>
      <v-col lg="3" cols="12" class="d-flex justify-center">
        <v-scale-transition origin="center">
          <img v-show="showBook" class="book-img" :src="openBook"/>
        </v-scale-transition>
      </v-col>
    </v-row>
    <v-row v-intersect.once="onIntersect" class="d-flex justify-center">
      <v-col lg="6" cols="10">
        <v-btn class="btn btn--secondary" color="secondary" block depressed @click="$router.push('/signup')">
          <span class="btn__text btn__text--white">{{ $t('homePage.jamNow') }}</span>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'WhyJambooks',
  data() {
    return {
      showBridge: false,
      showPlus: false,
      showPhone: false,
      showEqual: false,
      showBook: false,
    }
  },
  computed: {
    jambooksLogo() {
      return require('@/assets/Jambooks_Logo.svg')
    },
    bridge() {
      return require('@/assets/Bridge.svg')
    },
    handsPhone() {
      return require('@/assets/Hands_Phone.svg')
    },
    openBook () {
      return require('@/assets/Book.svg')
    },
  },
  methods: {
    onIntersect(entries) {
      let vm = this
      setTimeout(function() {
        vm.showBridge = entries[0].isIntersecting;
      }, 0);
      setTimeout(function() {
        vm.showPlus = entries[0].isIntersecting;
      }, 100);
      setTimeout(function() {
        vm.showPhone = entries[0].isIntersecting;
      }, 100);
      setTimeout(function() {
        vm.showEqual = entries[0].isIntersecting;
      }, 100);
      setTimeout(function() {
        vm.showBook = entries[0].isIntersecting;
      }, 200);
    },
  }
}
</script>

<style scoped>
  .animation-min-height {
    min-height: 22rem;
  }
  .image-container {
    margin-bottom: 5rem;
  }
  .symbol {
    font-size: 5rem;
  }
  @media only screen and (max-width: 960px) {
    .logo {
      width: 21rem;
      height: auto;
    }
    img {
      width: 14rem;
      height: auto;
    }
    .book-img {
      width: 18rem;
    }
  }
</style>
