<template>
  <v-container>
    <div style="display: block; text-align: center;">
      <h5 class="header__title_bold">Jam Messages</h5>
    </div>
    <v-row class="d-flex flex-column">
      <v-col class="d-flex justify-center">
        <span class="subheader__text header-span">
          View and edit your book message log.
        </span>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <v-container class="max-width">
          <v-pagination
              v-model="page"
              :length="(logs.length - 1)"
              :total-visible="7"
          />
        </v-container>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-card>
        <v-card-title>
          {{ current.prompt }}
        </v-card-title>
        <v-card-text>
          <v-img @click="onImageClick(k)" :src="`https://s3.us-west-2.amazonaws.com/${bucket}/${img.pathLowRes}`"   v-for="(img, k) in current.images" :key="k" contain>
            <template v-slot:placeholder>
              <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
              >
                <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          {{ current.txt }}
        </v-card-text>
        <v-card-text>
          --{{ current.jammer }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="onEditClick">Edit</v-btn>
        </v-card-actions>
      </v-card>

    </v-row>
    <v-row>
      <v-col cols="12">
      </v-col>
    </v-row>
    <v-dialog v-model="dialogCreateBook" max-width="450" persistent>
      <ProgressDialog
          :text="progressText"
        />
    </v-dialog>
    <v-dialog v-model="dialogEdit" max-width="450">
      <v-card>
        <v-card-title>Edit log</v-card-title>
        <v-card-text>
          <v-textarea
              label="Text"
              class="mb-5 mb-lg-10"
              v-model="current.txt"
              solo
              hide-details="auto"
          >
          </v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click="onSave">Save</v-btn>
          <v-btn color="red" @click="dialogConfirm=true">
            <span style="color: #fff">Delete</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogConfirm" max-width="400">
      <ConfirmDialog
          title="Are you sure you want to delete this log?"
          subtitle="This cannot be undone."
          @cancel="dialogConfirm = false"
          @confirm="onConfirmDelete"
      />
    </v-dialog>
    <v-snackbar
        :color="notify.color"
        elevation="24"
        min-width="none"
        height="50"
        v-model="notify.visible">
      <div class="snack-text">
        <!--        <v-icon large class="pr-3">mdi-check-circle</v-icon>-->
        <v-icon large class="pr-3" v-if="notify.icon">{{  notify.icon }}</v-icon>
        {{ notify.text }}
      </div>
    </v-snackbar>
    <v-dialog
        persistent
        v-model="dialogLoading"
        width="450"
    >
      <v-card
          color="primary"
          dark
      >
        <v-card-text class="pa-6 text-h5">
          {{  loadingMessage }}
          <v-progress-linear
              indeterminate
              color="white"
              class="mb-0 mt-5"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import Http from '@/api/Http';
import { ProgressDialog, ConfirmDialog } from '@/components/dialogs';

export default {
  name: 'JamLayout',
  components: {
    ProgressDialog,
    ConfirmDialog
  },
  props: {
    jam: {
      type: Object
    }
  },
  created() {
    if (this.$route.query.cancel) {
      this.cancelPayment();
    }
    this.getLog();
  },
  computed: {
    current () {
      if (this.logs[this.page]) {
        return this.logs[this.page];
      } else {
        return {prompt: ''};
      }
    },
    currentUser () {
      return this.$store.state.user;
    }
  },
  data() {
    return {
      api: Http.use('data'),
      dialogCreateBook: false,
      dialogEdit: false,
      dialogConfirm: false,
      dialogOrder: false,
      dialogLoading: false,
      loadingMessage: '',
      progressText: '',
      bucket: '',
      prompts: {},
      logs: [],
      page: 1,
      notify: {
        visible: false,
        icon: null,
        text: null,
        color: null
      }
    }
  },
  methods: {
    cancelPayment() {
      this.api.get('/orders/cancel-in-flight')
          .then(() => {
            this.$router.push(this.$route.path);
            this.notify = {
              visible: true,
              color: 'success',
              text: 'Order canceled',
              icon: 'mdi-check-circle'
            }
          })
          .catch(err => {
            if (err.response.data.error) {
              this.notify = {
                visible: true,
                color: 'red accent-2',
                text: err.response.data.error,
                icon: 'mdi-alert-circle-outline'
              }
            }
          })
    },
    onSave() {
      this.api.put(`/jams/${this.$route.params.id}/log/${this.page}`, {text: this.current.txt})
          .then(() => {
            this.dialogEdit = false;
            this.notify = {
              visible: true,
              color: 'success',
              text: 'Log updated',
              icon: 'mdi-check-circle'
            }
          })
          .catch (err => {
            this.notify = {
              visible: true,
              color: 'red accent-2',
              text: err.response.data.error ? err.response.data.error : err.message,
              icon: 'mdi-alert-circle-outline'
            }
          })
    },
    onConfirmDelete() {
      this.dialogConfirm = false;
      this.dialogEdit = false;
      this.api.delete(`/jams/${this.$route.params.id}/log/${this.page}`)
          .then(() => {
            this.logs.splice(this.page, 1);
            if (this.page > 1) {
              this.page--;
            }
            this.notify = {
              visible: true,
              color: 'success',
              text: 'Log deleted',
              icon: 'mdi-check-circle'
            }
          })
          .catch (err => {
            this.notify = {
              visible: true,
              color: 'red accent-2',
              text: err.response.data.error ? err.response.data.error : err.message,
              icon: 'mdi-alert-circle-outline'
            }
          })
    },
    onImageClick(index) {
      window.open(`https://s3.us-west-2.amazonaws.com/${this.bucket}/${this.current.images[index].path}`);
    },
    getLog() {
      this.api.get(`/jams/${this.$route.params.id}/log`)
          .then(ret => {
            this.logs = ret.log;
            this.logs.unshift('');
            this.bucket = ret.bucket;
          })
          .catch(err => {
            console.log(err);
          });
    },
    onOrderBook() {
      this.dialogOrder = true;
    },
    onEditClick() {
      this.dialogEdit = true;
    },
  }
}
</script>

<style scoped>
.subheader__text {
  font-size: 2.5rem;
  color: var(--v-primary-base);
  text-align: left;
}
</style>
