<template>
  <v-container class="container">
    <v-row class="d-flex mb-10">
     <v-col class="d-flex align-center flex-column">
      <h1 class="header__text">{{ $t('homePage.whyJam') }}</h1>
      <span class="subheader header-span">{{ $t('homePage.handsUp') }}</span>
     </v-col>
    </v-row>
    <v-row class="d-flex justify-center animation-min-height">
      <v-col xl="5" md="7" cols="12" order-md="1" order="2" class="d-flex align-center container-left">
        <v-slide-x-reverse-transition>
          <p v-show="showDocumenting" class="container__text" v-html="$t('homePage.documenting')"></p>
        </v-slide-x-reverse-transition>
      </v-col>
      <v-col xl="3" md="5" cols="12" order-md="2" order="1" class="d-flex container-right">
        <v-scale-transition origin="center">
          <img v-show="showDocumenting" :src="hecticImage"/>
        </v-scale-transition>
      </v-col>
    </v-row>
    <v-row v-intersect.once="onIntersectTotallyRemember" class="d-flex justify-center animation-min-height">
      <v-col xl="3" md="5" cols="12" class="d-flex container-left">
        <v-scale-transition origin="center">
          <img v-show="showTotallyRemember" :src="picturesImage"/>
        </v-scale-transition>
      </v-col>
      <v-col xl="5" md="7" cols="12" class="d-flex align-center container-right">
        <v-slide-x-transition>
          <p v-show="showTotallyRemember" class="container__text container__text--right" v-html="$t('homePage.youTotallyRemember')"></p>
        </v-slide-x-transition>
      </v-col>
    </v-row>
    <v-row v-intersect.once="onIntersectSocialMediaSucks" class="d-flex justify-center animation-min-height">
      <v-col xl="5" md="7" cols="12" order-md="1" order="2" class="d-flex align-center container-left">
        <v-slide-x-reverse-transition>
          <p v-show="showSocialMediaSucks" class="container__text" v-html="$t('homePage.socialMediaSucks')"></p>
        </v-slide-x-reverse-transition>
      </v-col>
      <v-col xl="3" md="5" cols="12"  order-md="2" order="1" class="d-flex container-right">
        <v-scale-transition origin="center">
          <img v-show="showSocialMediaSucks"  :src="socialMediaImage"/>
        </v-scale-transition>
      </v-col>
    </v-row>
    <v-row v-intersect.once="onIntersectEpicAventures" class="d-flex justify-center animation-min-height">
      <v-col xl="3" md="5" cols="12" class="d-flex container-left">
        <v-scale-transition origin="center">
          <img v-show="showEpicAventures" :src="epicImage"/>
        </v-scale-transition>
      </v-col>
      <v-col xl="5" md="7" cols="12" class="d-flex align-center container-right pl-5">
        <v-slide-x-transition>
          <p v-show="showEpicAventures" class="container__text container__text--right" v-html="$t('homePage.epicAdventures')"></p>
        </v-slide-x-transition>
      </v-col>
    </v-row>
    <v-row v-intersect.once="onIntersectBabyBook" class="d-flex justify-center animation-min-height">
      <v-col xl="5" md="7" cols="12" order-md="1" order="2" class="d-flex align-center container-left">
        <v-slide-x-reverse-transition>
          <p v-show="showBabyBook" class="container__text" v-html="$t('homePage.awesomeBabyBook')"></p>
        </v-slide-x-reverse-transition>
      </v-col>
      <v-col xl="3" md="5" cols="12" order-md="2" order="1" class="d-flex container-right">
        <v-scale-transition origin="center">
          <img v-show="showBabyBook" :src="babyBooksImage"/>
        </v-scale-transition>
      </v-col>
    </v-row>
    <v-row v-intersect.once="onIntersectNoApps" class="d-flex justify-center animation-min-height">
      <v-col xl="3" md="5" cols="12" class="d-flex container-left">
        <v-scale-transition origin="center">
          <img v-show="showNoApps" :src="noAppsImage"/>
        </v-scale-transition>
      </v-col>
      <v-col xl="5" md="7" cols="12"  class="d-flex align-center container-right">
        <v-slide-x-transition>
          <p v-show="showNoApps" class="container__text container__text--right" v-html="$t('homePage.noAppsRequired')"></p>
        </v-slide-x-transition>
      </v-col>
    </v-row>
    <v-row v-intersect.once="onIntersectStuck" class="d-flex justify-center animation-min-height">
      <v-col xl="5" md="7" cols="12" order-md="1" order="2" class="d-flex align-center container-left">
        <v-slide-x-reverse-transition>
          <p v-show="showStuck" class="container__text" v-html="$t('homePage.bestMemoriesStuck')"></p>
        </v-slide-x-reverse-transition>
      </v-col>
      <v-col xl="3" md="5" cols="12" order-md="2" order="1" class="d-flex container-right">
        <v-scale-transition origin="center">
          <img v-show="showStuck" :src="stuckImage"/>
        </v-scale-transition>
      </v-col>
    </v-row>
    <v-row v-intersect.once="onIntersectEnd">
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'WhyJamCard',
    data() {
      return {
        showDocumenting: false,
        showTotallyRemember: false,
        showSocialMediaSucks: false,
        showEpicAventures: false,
        showBabyBook: false,
        showNoApps: false,
        showStuck: false,
      }
    },
    computed: {
      hecticImage() {
        return require('@/assets/Hectic.svg')
      },
      picturesImage() {
        return require('@/assets/Pictures.svg')
      },
      socialMediaImage() {
        return require('@/assets/Social_Media.svg')
      },
      epicImage() {
        return require('@/assets/Epic.svg')
      },
      babyBooksImage() {
        return require('@/assets/Baby_Books.svg')
      },
      noAppsImage() {
        return require('@/assets/No_App.svg')
      },
      stuckImage() {
        return require('@/assets/Stuck.svg')
      }
    },
    methods: {
      onIntersectTotallyRemember (entries) {
        let vm = this
        setTimeout(function() {
          vm.showDocumenting = entries[0].isIntersecting;
        }, 0);
      },
      onIntersectSocialMediaSucks (entries) {
        let vm = this
        setTimeout(function() {
          vm.showTotallyRemember = entries[0].isIntersecting;
        }, 0);
      },
      onIntersectEpicAventures (entries) {
        let vm = this
        setTimeout(function() {
          vm.showSocialMediaSucks = entries[0].isIntersecting;
        }, 0);
      },
      onIntersectBabyBook (entries) {
        let vm = this
        setTimeout(function() {
          vm.showEpicAventures = entries[0].isIntersecting;
        }, 0);
      },
      onIntersectNoApps (entries) {
        let vm = this
        setTimeout(function() {
          vm.showBabyBook = entries[0].isIntersecting;
        }, 0);
      },
      onIntersectStuck (entries) {
        let vm = this
        setTimeout(function() {
          vm.showNoApps = entries[0].isIntersecting;
        }, 0);
      },
      onIntersectEnd(entries) {
        let vm = this
        setTimeout(function() {
          vm.showStuck = entries[0].isIntersecting;
        }, 0);
      },
    }
  }
</script>

<style scoped>
  .animation-min-height {
    min-height: 23rem;
  }
  .container {
    margin-bottom: 6.25rem;
  }
  .header__text {
    font-size: 7.5rem;
    line-height: 90%;
    text-align: center;
  }
  .subheader {
    font-size: 3.75rem;
    color: var(--v-secondary-base);
    text-align: center;
  }
  .container-right {
    justify-content: flex-end;
  }
  .container__text {
    font-size: 2rem;
  }
  .container__text--right {
    text-align: right;
  }
  @media only screen and (max-width: 960px) {
    .header__text {
      font-size: 3.75rem;
    }
    .subheader {
      font-size: 2rem;
    }
    .container-left {
      justify-content: center;
    }
    .container-right {
      justify-content: center;
    }
    .container__text {
      text-align: center;
      font-size: 1.5rem;
    } 
  } 
  @media only screen and (max-width: 1264px) {
    img {
      width: 18rem;
      height: auto;
    }
  }
</style>
