<template>
  <v-container>

    <h2 class="header header-span mb-8">{{ $t('createJamPage.helpDocument') }}</h2>
    <v-row class="d-flex justify-center mb-15">
      <v-col class="d-flex flex-column align-center" lg="3" md="6" xs="12">
        <Avatar
          class="d-flex justify-center"
          :avatar="avatars.luggageAvatar"
          :size="avatarSize"
          :commonAvatar="avatars.commonAvatarProperties"
        />
        <div class="triangle-up" id="triangle-up-purple"></div>
        <v-card class="avatar-card" color="accent" tile>
          <v-list class="my-5" color="accent">
            <v-list-item-group class="" v-model="form.type" @change="click">
              <v-list-item v-for="type in types.travel" :key="type.id" :value="type.id">
                <span class="list-item__text card__text--white"> {{ type.name }}</span>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col class="d-flex flex-column align-center" lg="3" md="6" xs="12">
        <Avatar
          class="d-flex justify-center"
          :avatar="avatars.notebookAvatar"
          :size="avatarSize"
          :commonAvatar="avatars.commonAvatarProperties"
        />
        <div class="triangle-up" id="triangle-up-green"></div>
        <v-card class="avatar-card" color="secondaryBackground" tile>
          <v-list class="my-5" color="secondaryBackground">
            <v-list-item-group class="d-flex flex-column align-left" v-model="form.type" @change="click">
              <v-list-item v-for="type in types.journal" :key="type.id" :value="type.id">
                <span class="list-item__text card__text--white">{{ type.name }}</span>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col class="d-flex flex-column align-center" lg="3" md="6" xs="12">
        <Avatar
          class="d-flex justify-center"
          :avatar="avatars.partyAvatar"
          :size="avatarSize"
          :commonAvatar="avatars.commonAvatarProperties"
        />
        <div class="triangle-up" id="triangle-up-teal"></div>
        <v-card class="avatar-card" tile color="secondary">
          <v-list class="my-5" color="secondary">
            <v-list-item-group class="" v-model="form.type" @change="click">
              <v-list-item v-for="type in types.event" :key="type.id" :value="type.id">
                <span class="list-item__text card__text--white"> {{ type.name }}</span>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col class="d-flex flex-column align-center" lg="3" md="6" xs="12">
        <Avatar
          class="d-flex justify-center"
          :avatar="avatars.crownAvatar"
          :size="avatarSize"
          :commonAvatar="avatars.commonAvatarProperties"
        />
        <div class="triangle-up" id="triangle-up-orange"></div>
        <v-card class="avatar-card" color="primary" tile>
          <v-list class="my-5" color="primary">
            <v-list-item-group class="" v-model="form.type" @change="click">
              <v-list-item v-for="type in types.person" :key="type.id" :value="type.id">
                <span class="list-item__text card__text--white">{{ type.name }}</span>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <v-form @submit.prevent="submit" lazy-validation v-model="valid" ref="form">
      <AlertError :error="error" />
      <v-row class="d-flex flex-column align-center field-box">
          <v-col class="d-flex flex-column" lg="6" cols="10">
            <span v-show="needsVip">
              <TextField
                  ref="vipName"
                  label="Special Person's Name"
                  v-model="form.vipName"
                  :rules="[rules.vip]"
              />
            </span>
            <span class="field-box__text">{{ $t('createJamPage.bookTitle') }}</span>
            <span class="field-box__text mb-5">{{ $t('createJamPage.dontWorry') }}</span>
            <TextField
              class="mb-5"
              ref="jamName"
              :placeholder="$t('createJamPage.nameYourJam')"
              :label="$t('createJamPage.nameYourJam')"
              v-model="form.name"
              :rules="[v => !!v || 'Name is required']"
            />
            <v-btn class="btn btn--accent mb-16" color="text" @click="submit" :disabled="!isValid">
              <span class="btn__text btn__text--white">
                {{ $t('createJamPage.go') }}
              </span>
            </v-btn>
          </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import Avatar from '@/components/Avatar';
import TextField from '@/components/TextField';
import AlertError from '@/components/AlertError';

  export default {
    name: 'Create',
    components: {
      AlertError,
      Avatar,
      TextField
    },
    props: {
      modelValue: String,
      jam: {
        type: Object,
        default: () => ({name: '', type: 0})
      },
      types: {
        type: Object,
        default: () => ({person: []})
      }
    },
    data() {
      return {
        avatarSize: 235,
        error: '',
        valid: true,
        form: {...this.jam},
        avatars: {
          commonAvatarProperties: {
            hoverDisabled: false,
          },
          luggageAvatar: {
            color: "accentBackground",
            text: this.$t('homePage.travel'),
            image: require('@/assets/Luggage.svg'),
          },
          notebookAvatar: {
            color: "primaryAction",
            text: this.$t('homePage.journal'),
            image: require('@/assets/Notebook.svg')
          },
          partyAvatar: {
            color: "secondaryBackgroundEmphasis",
            text: this.$t('homePage.specialEvent'),
            image: require('@/assets/Party.svg'),
          },
          crownAvatar: {
            color: "accentBackground",
            text: this.$t('homePage.specialPerson'),
            image: require('@/assets/Crown.svg'),
          },
        },
        rules: {
          vip: value => {
            if (this.needsVip && !value) {
              return 'Special person name is required';
            }
            return true;
          }
        }
      }
    },
    computed: {
      isValid () {
        return (this.form.type && this.form.name);
      },
      needsVip () {
        if (this.form.type) {
          return (this.types.person.filter(tp => tp.id === this.form.type)).length;
        } else {
          return false;
        }
      }
    },
    methods: {
      validateVip(val) {
        console.log(val);
        return true;
      },
      click (evt) {
        if (this.needsVip && evt) {
          this.$refs.vipName.$refs.input.focus();
        } else if (evt) {
          this.$refs.jamName.$refs.input.focus();
        }
      },
      submit () {
        if (this.$refs.form.validate()) {
          if (!this.form.type) {
            this.error = 'step1.typeRequired';
          } else {
            this.$emit('save', this.form);
          }
        }
      }
    },
    watch: {
      jam: function(newVal) {
        this.form = {...newVal};
      }
    }
  }
</script>

<style scoped>
  .header {
    color: var(--v-secondary-base);
    text-align: center;
    line-height: 100%;
  }
  .avatar-card {
    width: 18rem;
  }
  .v-input >>> input::placeholder {
    text-align: center;
  }
  .list-item__text {
    font-weight: bold;
    font-size: 1.75rem;
    /*text-align: center;*/
  }
  .field-box__text {
    font-size: 1.75rem;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
  }
  .triangle-up {
    width: 0;
    height: 0;
    border-left: 14px solid transparent;
    border-right: 14px solid transparent;
  }
  #triangle-up-purple{
    border-bottom: 28px solid var(--v-accent-base);
  }
  #triangle-up-green{
    border-bottom: 28px solid var(--v-secondaryBackground-base);
  }
  #triangle-up-teal{
    border-bottom: 28px solid var(--v-secondary-base);
  }
  #triangle-up-orange {
    border-bottom: 28px solid var(--v-primary-base);
  }

  @media only screen and (max-width: 1264px) {
    .header {
      font-size: 3.25rem;
    }
  }
</style>
