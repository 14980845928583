<template>
   <div class="footer-img"></div>
</template>

<script>
  export default {
    name: 'HomeImageFooter',
  }
</script>

<style scoped>
  .footer-img {
    background-image: url(../assets/Home_Footer.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 50rem;
  }

  @media only screen and (max-width: 1264px) {
    .footer-img {
      background-image: url(../assets/Home_Footer_Responsive.jpg);
      background-size: cover;
      height: 28rem;
      background-position: center;
    }
  }
</style>
