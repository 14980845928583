<template>
  <v-container class="pa-0">
    <v-card class="card-container">
      <v-row class="d-flex flex-column align-center my-12">
        <v-col cols="10">
          <v-row class="d-flex flex-column align-center">
            <img alt="Jambooks logo" :src="jambookCircleLogo"/>
            <h1 class="header__title">account</h1>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="d-flex flex-column align-center my-12">
        <v-col cols="10">
          <v-btn class="btn btn--secondary mb-10" block color="secondary" @click="handleClick('profile')">
            <span class="justify-center btn__text btn__text--white py-6">
              {{ $t('accountPage.profile') }}
            </span>
          </v-btn>

<!--          <v-btn class="btn btn&#45;&#45;secondary mb-10" block depressed color="secondary" @click="handleClick('payments')">-->
<!--            <span class="justify-center btn__text btn__text&#45;&#45;white py-6">-->
<!--              {{ $t('accountPage.payments') }}-->
<!--            </span>-->
<!--          </v-btn>-->

          <v-btn class="btn btn--secondary mb-10" block depressed color="secondary" @click="handleClick('orders')">
            <span class="justify-center btn__text btn__text--white py-6">
              {{ $t('accountPage.orders') }}
            </span>
          </v-btn>


<!--          <v-btn class="btn btn&#45;&#45;secondary mb-10" block depressed color="secondary">-->
<!--            <span class="justify-center btn__text btn__text&#45;&#45;white py-6">-->
<!--              {{ $t('accountPage.prompts') }}-->
<!--            </span>-->
<!--          </v-btn>-->
<!--          <v-btn-->
<!--            class="btn btn&#45;&#45;secondary mb-10"-->
<!--            to="/account/jammers"-->
<!--            block-->
<!--            depressed -->
<!--            color="secondary"-->
<!--          >-->
<!--            <span class="justify-center btn__text btn__text&#45;&#45;white py-6">-->
<!--              {{ $t('accountPage.jammers') }}-->
<!--            </span>-->
<!--          </v-btn>-->
<!--          <v-btn class="btn btn&#45;&#45;secondary mb-10" block  depressed color="secondary">-->
<!--            <span class="justify-center btn__text btn__text&#45;&#45;white py-6">-->
<!--              {{ $t('accountPage.bookLayout') }}-->
<!--            </span>-->
<!--          </v-btn>-->
<!--          <v-btn class="btn btn&#45;&#45;secondary" block depressed color="secondary">-->
<!--            <span class="justify-center btn__text btn__text&#45;&#45;white py-6">-->
<!--              {{ $t('accountPage.history') }}-->
<!--            </span>-->
<!--          </v-btn>-->
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>

  export default {
    name: 'AccountInfoCard',
    computed: {
      jambookCircleLogo() {
        return require('@/assets/Jambook_Logo_Circle.svg');
      }
    },
    methods: {
      handleClick(route) {
        this.$emit('menuClick', route);
      }
    }
  }
</script>

<style scoped>
  .card-container {
    border-radius: 20px;
    border: 3px solid #234052;
  }
  .btn__text {
    font-size: 2.8rem;
  }
</style>
