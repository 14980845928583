<template>
  <v-container>
    <ProgressAvatars
        :step="parseInt(step)"
        v-if="step!== 'finish'"
    />
    <JamTypeName
      v-if="step === '1'"
      :types="types"
      :jam="jam"
      @save="save"
    />
    <JamWhen
        v-if="step === '2'"
        :jam="jam"
        @save="save"
    />
    <JamPropts
      v-if="step === '3'"
      :jam="jam"
      @save="save"
      />
    <JamPay
      v-if="step === '4'"
      :jam="jam"
      :typeName="typeName"
      :promoLoading="promoLoading"
      :price="price"
      @checkout="onCheckout"
      @promo="handleApplyPromo"
      @promo-remove="onPromoRemove"
      :promoResult="promoResult"
      />
    <JamCreateFinish
        v-if="step === 'finish'"
        :jam="jam"
      />
    <v-dialog
        persistent
        v-model="dialog"
        width="450"
    >
      <v-card
          color="primary"
          dark
      >
        <v-card-text class="pa-6 text-h5">
          {{  dialogAction }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0 mt-5"
            />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
        :color="notify.color"
        elevation="24"
        min-width="none"
        height="50"
        v-model="notify.visible">
      <div class="snack-text">
        <v-icon large class="pr-3" v-if="notify.icon">{{  notify.icon }}</v-icon>
        {{ notify.text }}
      </div>
    </v-snackbar>
  </v-container>
</template>

<script>
import ProgressAvatars from '@/components/ProgressAvatars';
import JamTypeName from '../components/JamTypeName';
import JamWhen from '../components/JamWhen';
import JamPropts from '../components/JamPrompts';
import JamPay from '@/components/JamPay';
import JamCreateFinish from '@/components/jam/create/JamCreateFinish';
import { getterNames as typeGetterNames } from '@/store/typesStore';
// import { getterNames as jamGetterNames, mutationNames } from '@/store/jamStore';
// import { mutationNames } from '@/store/jamStore';
import { getterNames } from '@/store/global';
import { getTypes } from '@/services/typeService';
import Http from '@/api/Http';

export default {
  name: 'JamsCreate',
  components: {
    JamCreateFinish,
    ProgressAvatars,
    JamTypeName,
    JamWhen,
    JamPropts,
    JamPay
  },
  data() {
    return {
      disableGo: true,
      api: Http
          .fail(this.fail)
          .use('data'),
      jam: {},
      price: 0.00,
      dialog: false,
      dialogAction: 'Updating jam...',
      defaultPaymentType: { },
      promoLoading: false,
      promoResult: {},
      notify: {
        visible: false,
        icon: null,
        text: null,
        color: null
      }
    }
  },
  computed: {
    types: {
      get () {
        return this.$store.getters[typeGetterNames.getByCat];
      }
    },
    selected() {
      return this.jam;
      // return this.$store.getters[jamGetterNames.getSelected];
    },
    step() {
      return this.$route.params.step;
    },
    stripeKey () {
      return this.$store.getters[getterNames.getStripeKey];
    },
    typeName () {
      let ret = this.$store.getters[typeGetterNames.getById](this.jam.type);
      if (ret) {
        return this.$t(ret.name);
      } else {
        return '';
      }
    }
  },
  created () {
    // console.log(typeGetterNames.getById);
    // load the jam
    getTypes(this.$store);
    this.getPricing();
    if (this.$route.params.id === undefined) {
      this.api.post('/jams', {
        name: '',
        freq: 0,
        selTz: 'MST',
        tod: 1,
        lang: 'en',
        status: 'setup'
      })
        .then(jam => {
          this.jam = jam;
          this.$router.push('/jam/' + jam._id + '/create/1');
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      if (this.step === 'finish') {
        this.dialog = true;
        this.dialogAction = 'Creating Jam phone number...'
        this.api.post(`/jams/${this.$route.params.id}/start`)
            .then(() => {
              this.$router.push(`/jam/${this.$route.params.id}/prompts`);
              this.notify = {
                visible: true,
                color: 'success',
                text: 'Jam created.',
                icon: 'mdi-check-circle'
              }
            })
            .catch(err => {
              this.dialog = false;
              if (err.response.data.error) {
                this.notify = {
                  visible: true,
                  color: 'red accent-2',
                  text: err.response.data.error,
                  icon: 'mdi-alert-circle-outline'
                }
              }
            })
            .catch(err => {
              console.log(err);
            });
      } else {
        this.api.get('/jams/' + this.$route.params.id)
            .then(jam => {
              this.jam = jam;
            })
            .catch(err => {
              if (err.response.status === 404) {
                this.$router.push('/jam/?404=true');
              } else {
                console.log(err);
              }
            });
      }
    }
    if (this.$route.query.cancel) {
      this.cancelPayment();
    }
  },
  methods: {
    onPromoRemove() {
      this.api.delete('/orders/promo')
          .then(() => {
            this.notify = {
              visible: true,
              color: 'success',
              text: 'Promotional code removed from order',
              icon: 'mdi-check-circle'
            }
          })
    },
    getPricing() {
      this.api.get('/inventory/pricing')
          .then(result => {
            this.price = result.price;
          });
    },
    cancelPayment() {
      this.api.post('/orders/cancel-in-flight')
          .then(() => {
            this.$router.push(this.$route.path);
            this.notify = {
              visible: true,
              color: 'success',
              text: 'Order canceled',
              icon: 'mdi-check-circle'
            }
          })
          .catch(err => {
            if (err.response.data.error) {
              this.notify = {
                visible: true,
                color: 'red accent-2',
                text: err.response.data.error,
                icon: 'mdi-alert-circle-outline'
              }
            }
          })
    },
    fail(evt) {
      if (evt.response.status === 401) {
        this.$emit('notify', {
          visible: true,
          color: 'red accent-2',
          text: 'Unauthorize',
          icon: 'mdi-alert-circle-outline'
        });
      }
    },
    save (args) {
      this.dialog = true;
      this.api.put(`/jams/${args._id}`, args)
        .then(() => {
          this.jam = args;
          this.dialog = false;
          this.$router.push('/jam/' + this.$route.params.id + '/create/' + (parseInt(this.step) + 1));
        });
    },
    handleApplyPromo (code) {
      // call the server with the promo code.
      // if it's valid it'll get set on the users session
      // if it's not valid we give them an error
      this.promoLoading = true;
      this.api.post('/orders/promo', { promo: code })
          .then((result) => {
            // console.log(result);
            // console.log(this.jam);
            this.promoResult = result;
            this.promoLoading = false;
            this.notify = {
              visible: true,
              color: 'success',
              text: 'Promotional code applied to order.',
              icon: 'mdi-check-circle'
            }
          })
          .catch(err => {
            this.promoResult = err.response.data;
            this.promoLoading = false;
            this.notify = {
              visible: true,
              color: 'red accent-2',
              text: err.response.data.error,
              icon: 'mdi-alert-circle-outline'
            };
          })
      // console.log(code);
    },
    onCheckout() {
      this.dialogAction = 'Redirecting to checkout...';
      this.dialog = true;
      this.api.post('/orders/create', { jamId: this.$route.params.id })
          .then(resp => {
            // console.log('RES', resp);
            this.dialog = false;
            window.location = resp.url;
          })
          .catch(err => {
            this.notify = {
              visible: true,
              color: 'red accent-2',
              text: err.response.data.error,
              icon: 'mdi-alert-circle-outline'
            }
          });
    },
    finish (args) {
      this.dialog = true;
      let token = args.token;
      // let payment = args.payment;
      let paymentTypeId = args.paymentTypeId;
      delete args.paymentTypeId;
      delete args.token;
      delete args.payment;
      // console.log(args);
      // console.log(token, payment);

      this.dialogAction = 'Creating payment...';
      this.api.post('/payments/charge', {
        token,
        itemId: args.itemId,
        paymentTypeId,
        jamId: this.$route.params.id
      })
        .then(() => {
          this.dialogAction = 'Creating Jam phone number...';
          return this.api.post('/sms/create', {
            jamId: this.$route.params.id
          });
        })
        .then(result => {
          this.jam.phoneNumber = result.phoneNumber;
          args.phoneNumber = result.phoneNumber;
          this.dialogAction = 'Saving Jam...';
          args.status = 'active';
          return this.api.put(`/jams/${args._id}`, args)
        })
        .then(() => {
          this.dialogAction = 'Finished';
          this.dialog = false;
          this.$router.push('/jam/' + this.$route.params.id);
        })
        .catch(err => {
          console.log(err);
        });
    },
    next () {
      this.$router.push('/jam/' + this.$route.params.id + '/create/' + (parseInt(this.step) + 1));
    }
  }
}
</script>
<style scoped>
.snack-text {
  font-size: 1.6rem;
  text-align: center;
  display: block;
  margin-top: 3px;
  color: #ffffff;
}
</style>