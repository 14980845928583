import { render, staticRenderFns } from "./TeamMember.vue?vue&type=template&id=61bc85b2&scoped=true&"
import script from "./TeamMember.vue?vue&type=script&lang=js&"
export * from "./TeamMember.vue?vue&type=script&lang=js&"
import style0 from "./TeamMember.vue?vue&type=style&index=0&id=61bc85b2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61bc85b2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VScaleTransition } from 'vuetify/lib/components/transitions';
import { VSlideYTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VContainer,VImg,VScaleTransition,VSlideYTransition})


/* vuetify-loader */
import installDirectives from "!../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Intersect from 'vuetify/lib/directives/intersect'
installDirectives(component, {Intersect})
