// import { checkAuthAsync, loginAsync } from '@/api/local';
import api from '@/api/local';

import { mutationNames } from '@/store/global';
import { mutationNames as jamMutationNames } from '@/store/jamStore';

export const authCheck = async (store) => {
    try {
        store.commit(mutationNames.LOADING, true);
        let ret = await api.get('/auth/check');
        store.commit(mutationNames.UPDATE_CURRENT_USER, ret.data.profile);
        store.commit(mutationNames.LOADING, false);
        return true;
    } catch (e) {
        console.log(e.response.data);
        store.commit(mutationNames.UPDATE_CURRENT_USER, false);
        store.commit(mutationNames.LOADING, false);
        return false;
    }
}

/**
 *
 * @param store
 * @param phone
 * @param pass
 * @returns Boolean
 * @throws {Object} error
 */
export const login = async (store, phone, pass) => {
    try {
        let ret = await api.post('/auth/login', {phone, pass});
        store.commit(mutationNames.UPDATE_CURRENT_USER, ret);
        return true;
    } catch (e) {
        store.commit(mutationNames.UPDATE_CURRENT_USER, false);
        throw(e.response.data);
    }
}

export const logout = async (store) => {
    await api.get('/auth/logout');
    store.commit(mutationNames.UPDATE_CURRENT_USER, false);
    store.commit(jamMutationNames.RESET);
    // console.log(jamMutationNames.RESET);
}
