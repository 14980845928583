// axios.js
import axios from 'axios'

// create instance
const instance = axios.create({
    baseURL: '/api/'
});

// export new instance
export default instance;
