import Vue from 'vue'
import Vuetify, { VSnackbar, VBtn, VIcon } from 'vuetify/lib'
import VuetifyToast from 'vuetify-toast-snackbar-ng'

Vue.use(Vuetify, {
  components: {
    VSnackbar,
    VBtn,
    VIcon
  }
})

Vue.use(VuetifyToast,{
  x: 'center', // default
  y: 'bottom', // default
  color: 'success', // default
  icon: 'mdi-check-circle',
  iconColor: '', // default
  classes: [
    'body-2'
  ],
  timeout: 3000, // default
  dismissable: true, // default
  multiLine: false, // default
  vertical: false, // default
  queueable: false, // default
  showClose: false, // default
  closeText: '', // default
  closeIcon: 'close', // default
  closeColor: '', // default
  slot: [], //default
  shorts: {
    custom: {
      color: 'purple'
    }
  },
  property: '$toast' // default
})

const palette = {
  orange: {
    regular: '#D88054',
    light: '#DB8054',
    dark: '#CD551D',
  },
  red: {
    regular: '#9B4754',
  },
  pink: {
    regular: '#F0A7B8',
  },
  purple: {
    regular: '#B480BF',
  },
  blue: {
    regular: '#264254',
  },
  teal: {
    regular: '#77B2B6',
  },
  green: {
    light: '#84B622',
    dark: '#709A1E',
    darker: '#438901',
  },
  grey: {
    regular: '#707070',
    light: '#F0F0F0',
  },
  white: '#fff',
  black: '#000',

};

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: palette.orange.regular,
        primaryLight: palette.orange.light,
        primaryAction: palette.orange.dark,
        primaryWarning: palette.red.regular,
        secondary: palette.teal.regular,
        secondaryBackground: palette.green.light,
        secondaryBackgroundEmphasis: palette.green.dark,
        tertiaryBackground: palette.grey.light,
        text: palette.blue.regular,
        secondaryText: palette.black,
        tertiaryText: palette.grey.regular,
        textOnSecondary: palette.white,
        footerText: palette.white,
        background: palette.white,
        accentBackground: palette.pink.regular,
        accent: palette.purple.regular,
        secondaryAccent: palette.green.darker,
      },
    },
  },
})
