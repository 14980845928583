<template>
  <v-container class="px-0 pa-0" fluid>
    <div class="header d-flex flex-column justify-center align-center">
      <h1 class="header__title">{{ $t("pricingPage.title") }}</h1>
      <h3 class="header__subtitle">{{ $t("pricingPage.subtitle") }}</h3>
    </div>
    <div class="pricing-content">
      <div class="pricing-feature-image" />
      <div class="pricing-feature-gradient">
        <div class="d-flex justify-center price-cards">
          <PricingCard
              class="price-card ma-2 ma-sm-5 ma-md-9"
              :title="$t('pricingPage.bookTypes.hardCover')"
              :dimensions="
              $t('pricingPage.bookTypes.dimensions', {
                width: bookDetails.hardCover.width,
                height: bookDetails.hardCover.height,
              })
            "
              :price="bookDetails.hardCover.price"
              :price-per="
              $t('pricingPage.bookTypes.priceText', {
                price: bookDetails.hardCover.pricePer,
                pageCount: minPageCount,
              })
            "
          />
          <PricingCard
              class="price-card ma-2 ma-sm-5 ma-md-9"
              cardClass="variant"
              :title="$t('pricingPage.bookTypes.softCover')"
              :dimensions="
              $t('pricingPage.bookTypes.dimensions', {
                width: bookDetails.softCover.width,
                height: bookDetails.softCover.height,
              })
            "
              :price="bookDetails.softCover.price"
              :price-per="
              $t('pricingPage.bookTypes.priceText', {
                price: bookDetails.softCover.pricePer,
                pageCount: minPageCount,
              })
            "
          />
        </div>
        <div
            v-intersect.once="(entries) => onIntersect(entries, 'showFeatures')"
            class="features-content"
        >
          <v-slide-y-transition>
            <ul v-if="showFeatures" class="features-list">
              <li>{{ $t("pricingPage.detailText.colors") }}</li>
              <li>{{ $t("pricingPage.detailText.customizable") }}</li>
              <li>{{ $t("pricingPage.detailText.cover") }}</li>
              <li>{{ $t("pricingPage.detailText.pageQuality") }}</li>
              <li>
                {{
                  $t("pricingPage.detailText.pageCount", {
                    min: minPageCount,
                    max: maxPageCount,
                  })
                }}
              </li>
            </ul>
          </v-slide-y-transition>
          <v-scale-transition>
            <div
                v-if="showFeatures"
                class="button-container d-flex justify-center"
            >
              <v-btn
                  class="btn mx-10 px-16"
                  color="text"
                  elevation="0"
                  :to="{ path: '/signup' }"
              >
                <span class="btn__text btn__text--white font-weight-bold">
                  {{ $t("pricingPage.start") }}
                </span>
              </v-btn>
              <v-btn
                  class="btn mx-10"
                  color="text"
                  elevation="0"
                  outlined
                  style="border-width: 4px"
                  min-width="175px"
              >
                <a href="http://help.jambooks.co" target="_blank">
                <span class="btn__text font-weight-bold">
                  {{ $t("footer.faq") }}
                </span>
                </a>
              </v-btn>
            </div>
          </v-scale-transition>
        </div>
      </div>
      <img src="@/assets/stack_of_books.png" class="book-stack" width="100%" />
      <Inspiration />
    </div>
  </v-container>
</template>

<script>
import PricingCard from "@/components/PricingCard";
import Inspiration from "@/components/Inspiration.vue";
import AnimationTriggerMixin from "@/mixins/AnimationTriggerMixin";
import { MIN_PAGE_COUNT, MAX_PAGE_COUNT, BOOK_DETAILS } from "@/utils/Const";

export default {
  name: "Pricing",
  components: {
    PricingCard,
    Inspiration,
  },
  mixins: [AnimationTriggerMixin],
  data() {
    return {
      showFeatures: false,
    };
  },
  computed: {
    bookDetails() {
      return BOOK_DETAILS;
    },
    minPageCount() {
      return MIN_PAGE_COUNT;
    },
    maxPageCount() {
      return MAX_PAGE_COUNT;
    },
  },
};
</script>

<style scoped lang="scss">
//@import "~vuetify/src/styles/styles.sass";

.header {
  margin-top: 100px;
  margin-bottom: 10px;
}
.header__subtitle {
  color: var(--v-secondary-base);
  text-align: center;
  font-size: 60px;
  font-weight: 600;
  line-height: 150%; /* 90px */
  text-transform: uppercase;
}

.pricing-content {
  position: relative;
  z-index: 1;
}

.pricing-feature-image {
  height: 400px;
  background-image: url("../assets/pricing_feature_bg.jpeg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transform: rotate(180deg);
}

.pricing-feature-gradient {
  position: relative;
  height: 900px;
  background-color: var(--v-secondary-base);
}

.price-cards {
  position: absolute;
  width: 100%;
  bottom: 574px;
}

.price-card {
  max-width: calc(50% - 72px);
}

.features-content {
  position: absolute;
  bottom: 60px;
  left: 0px;
  right: 0px;
}

.features-list {
  font-size: 40px;
  font-weight: 600;
  line-height: normal;
  width: fit-content;
  margin: 0 auto 120px;
}

.features-list li {
  color: var(--v-textOnSecondary-base);
  font-weight: 600;
  margin-left: 40px;
}

.button-container {
  margin: 0 auto;
}

.book-stack {
  position: relative;
  z-index: -1;
  margin-top: -10.5%;
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .header {
    margin-bottom: 140px;
  }

  .header__subtitle {
    font-size: 36px;
  }

  .pricing-feature-gradient {
    height: 880px;
  }

  .price-cards {
    bottom: 740px;
  }

  .price-card {
    max-width: calc(50% - 40px);
  }

  .features-content {
    bottom: 120px;
  }

  .features-list {
    font-size: 36px;
    max-width: 460px;
    margin-bottom: 74px;
  }

  .button-container {
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .header {
    margin-top: 60px;
    margin-bottom: 20px;
  }

  .header__title {
    font-size: 48px;
  }

  .header__subtitle {
    font-size: 24px;
  }

  .pricing-feature-image {
    height: 215px;
  }

  .pricing-feature-gradient {
    height: 670px;
  }

  .price-cards {
    bottom: 542px;
  }

  .price-card {
    max-width: calc(50% - 16px);
  }

  .features-content {
    bottom: 90px;
  }

  .features-list {
    font-size: 24px;
    max-width: 315px;
  }

  .button-container {
    gap: 20px;
  }

  .features-content {
    bottom: 90px;
  }
}
</style>
