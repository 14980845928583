import axios from './axios';
import * as plugins from './plugins';

class Http {
    axios;
    error;
    loading;
    fails;
    dones;
    after;

    constructor (axios) {
        this.axios = axios;
        this.loading = false;
        this.dones = new Set();
        this.fails = new Set();
        this.after = [];
    }

    use (name, ...params) {
        const plugin = plugins[name];
        if (!plugin) {
            throw new Error(`No such plugin "${name}"`);
        }
        plugin(this, ...params);
        return this;
    }

    request (config, data) {
        this.error = false;
        this.loading = true;

        // variables
        let { method, url } = config
        method = method.toLowerCase()

        // sanity check
        if (typeof this.axios[method] !== 'function') {
            throw new Error(`No such HTTP method '${method}'`);
        }

        // setup object
        config = Object.assign({}, config)
        config.method = method
        config.url = url

        // data
        if (data) {
            if (method === 'get' && this.isObject(data) && !url.includes('?')) {
                config.params = data
            }
            else {
                config.data = data
            }
        }

        // loading
        const promise = this.axios.request(config);

        return promise
            .then(res => {
                this.loading = false;
                this.after.forEach(fn => {
                    const result = fn(res)
                    if (typeof result !== 'undefined') {
                        res = result
                    }
                })
                this.dones.forEach(fn => fn(res));
                return res;
            })
            .catch(error => {
                this.loading = false;
                this.error = error;
                this.fails.forEach(fn => fn(error));
                return Promise.reject(error);
            });
    }


    get(url, data, options) {
        return this.request(Object.assign({}, options, {url, method: 'get'}), data);
    }

    post(url, data, options) {
        return this.request(Object.assign({}, options, {url, method: 'post'}), data);
    }

    put(url, data, options) {
        return this.request(Object.assign({}, options, {url, method: 'put'}), data);
    }

    patch(url, data, options) {
        return this.request(Object.assign({}, options, {url, method: 'patch'}), data);
    }

    delete(url, data, options) {
        return this.request(Object.assign({}, options, {url, method: 'delete'}), data);
    }

    done (callback) {
        this.dones.add(callback);
        return this;
    }

    fail (callback) {
        this.fails.add(callback);
        return this;
    }

    isObject (value) {
        return typeof value === 'object' && !!value && !Array.isArray(value)
    }
}

export default new Http(axios);
