import Vue from 'vue'
import Vuex from 'vuex';
import * as jamModule from './jamStore';
import * as typesModules from './typesStore';
import * as globalModule from './global';

Vue.use(Vuex);

export default (() => {
    const store = new Vuex.Store(globalModule.createModule());
    store.registerModule(jamModule.name, jamModule.createModule());
    store.registerModule(typesModules.name, typesModules.createModule());
    return store;
})();

// const createStore = () => {
//     const store = new Vuex.Store(globalModule.createModule());
//     store.registerModule(jamModule.name, jamModule.createModule());
//     store.registerModule(typesModules.name, typesModules.createModule());
//     return store;
// }
//
// export { createStore };
