import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Account from '@/views/Account.vue'
import JamCreate from '@/views/JamCreate.vue'
import Login from '@/views/Login.vue'
import Products from '@/views/Products.vue'
import About from '@/views/About.vue'
import Privacy from '@/views/Privacy.vue';
import MenuCards from '@/components/MenuCards.vue';
import JamPromptsEdit from '@/components/JamPromptsEdit.vue';
import JamLayout from '@/components/JamLayout.vue';
import store from '@/store';
import { mutationNames } from '@/store/global'

// import { mutationNames } from '@/store/global'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'Home', component: Home, meta: { requiresAuth: false } },
  {
    path: '/account',
    component: Account,
    children : [
      { path: 'profile', name: 'AcocuntProfile', component: () => import('@/views/AccountProfile.vue') },
      { path: 'payments', name: 'AccountPayments', component: () => import('@/views/AccountPayments.vue') },
      { path: 'orders', name: 'AccountOrders', component: () => import('@/views/AccountOrders.vue') },
      { path: 'orders/:id', name: 'AccountOrderDetails', component: () => import('@/views/AccountOrderDetail.vue') },
      { path: 'orders/:id/:action', name: 'AccountOrderDetailAction', component: () => import('@/views/AccountOrderDetail.vue') }
    ]
  },
  { path: '/order/:id/:action', name: 'OrderDetailsDigital', component: () => import('@/views/OrderDetails.vue'), meta: { requiresAuth: false }},
  { path: '/order/:id', name: 'OrderDetails', component: () => import('@/views/OrderDetails.vue'), meta: { requiresAuth: false }},
  { path: '/jams', name: 'JamsIndex', component: () => import('@/views/Jams.vue') },
  { path: '/jam/:id/create/:step', name: 'JamsCreate' ,component: JamCreate},
  { path: '/create/', name: 'JamsCreateDefault' ,component: JamCreate},
  {
    path: '/jam', name: 'JamsShow', component: () => import('@/views/Jam.vue'),
    children: [
      { path: ':id', name: 'jamcards', component: MenuCards},
      { path: ':id/jammers', name: 'Jammers', component: () => import('@/views/Jammers')},
      { path: ':id/prompts', name: 'JamPromptsEdit', component: JamPromptsEdit},
      { path: ':id/layout', name: 'JamLayout', component: JamLayout},
      { path: ':id/payment', name: 'JamPayment', component: () => import('@/components/JamBookPayment')},
      { path: ':id/finishSetup', name: 'FinishSetup', component: JamLayout},
      { path: ':id/cancelSetup', name: 'CancelSetup', component: JamLayout}
    ]
  },
  { path: '/checkout', name: 'Checkout', component: () => import('@/views/CheckoutView')}, // not used
  {
    path: '/signup',
    name: 'Signup',
    meta: { requiresAuth: false },
    component: () => import('@/views/Signup.vue'),
  },
  {
    path: '/signup/register',
    name: 'Register',
    meta: { requiresAuth: false},
    component: () => import('@/views/SignupRegister')
  },
  {
    path: '/signup/validate/:id',
    name: 'EmailValidate',
    meta: { requiresAuth: false},
    component: () => import('@/views/SignupValidateEmail')
  },

  { path: '/admin', component: () => import('@/views/Admin'),
    children: [
      { path: '/', name: 'Admin', component: () => import('@/components/admin/AdminHome')},
      { path: 'types', name: 'admin-types', component: () => import('@/views/AdminJamTypes')},
      { path: 'prompts', name: 'admin-prompts', component: () => import('@/views/AdminPrompts')},
      { path: 'users', name: 'admin-users', component: () => import('@/views/AdminUsersView')},
      { path: 'system', name: 'admin-system', component: () => import('@/views/AdminSystem.vue')}
    ]
  },
  { path: '/ways', name: 'Ways', meta: { requiresAuth: false }, component: () => import('@/views/Ways') },
  { path: '/login', name: 'Login', meta: { requiresAuth: false }, component: Login },
  { path: '/forgot', name: 'AuthLogin', meta: { requiresAuth: false }, component: () => import('@/views/AuthForgot.vue') },
  { path: '/reset/:token', name: 'AuthResetVue', meta: { requiresAuth:  false }, component: () => import('@/views/AuthReset.vue') },
  { path: '/logout', name: 'logout', component: Home, meta: { requiredAuth: false } },
  { path: '/products', meta: { requiresAuth: false }, name: 'Pricing', component: Products },
  { path: '/about', name: 'About', meta: { requiresAuth: false }, component: About },
  { path: '/privacy', name: 'PrivacyPolicy', meta: { requiresAuth: false  }, component: Privacy },
  {
    path: '*',
    component: () => import('@/views/404.vue'),
    meta: {
      requiresAuth: false
    }
  }
]

const router = new VueRouter({
  scrollBehavior: () => ({ x: 0, y: 0 }),
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth === false)) {
    next();
  } else {
    if (!store.getters.currentUser) {
      store.commit(mutationNames.SAVE_UNAUTH_PATH, to.fullPath);
      store.commit(mutationNames.LOADING, true);
      next('/login');
      return;
    }
  }
  next();
})

// check isAuthenticated with non public routes
// router.beforeEach((to, from, next) => {
//   window.scrollTo(0,0);
//   if (to.name === 'logout') {
//     router.app.$store.commit(mutationNames.UPDATE_CURRENT_USER, false);
//     next('/');
//   } else {
//     console.log('beforeEach');
//     const requiresAuth = (to.meta.requiresAuth !== false) ? true : to.meta.requiresAuth;
//     if (!router.app.$store.state.isAuthenticated && requiresAuth) {
//       router.app.$store.commit(mutationNames.SAVE_UNAUTH_PATH, to.path);
//       next('/login')
//     } else {
//       next()
//     }
//   }
// });

export default router;
