import Vue from 'vue';
export const name = 'jam';

export const getterNames = {
    getJams: `${name}/getJams`,
    getSelected: `${name}/getSelected`,
    getNew: `${name}/getNew`,
    isLoaded: `${name}/isLoaded`
}

export const mutationNames = {
    SAVE_LIST: `${name}/SAVE_LIST`,
    SELECT: `${name}/SELECT`,
    UPDATE: `${name}/UPDATE`,
    RESET: `${name}/RESET`,
    REPLACE: `${name}/REPLACE`,
    ADD: `${name}/ADD`
}

export const createInitialState = () => ({
    all: {},
    ids: [],
    selectedId: null,
    loaded: false
})

function createModule() {
    const module = {
        namespaced: true,
        state: () => createInitialState(),
        getters: {
            getJams: (state) => (state.ids.map(jamId => state.all[jamId])),
            getSelected: (state) => (state.all[state.selectedId]),
            getNew: (state) => (state.all['new']),
            isLoaded: (state) => (state.loaded)
        },
        mutations: {
            SAVE_LIST: (state, payload) => {
                if (payload.length) {
                    state.ids = payload.map(p => p._id);
                    let obj = Object.assign(
                        {},
                        ...payload.map(jam => ({
                            [jam._id]: jam
                        }))
                    );
                    Vue.set(state, 'all', obj);
                }
            },
            UPDATE: (state, payload) => {
                // Vue.set(state.all, payload.id, Object.assign(state.all[payload.id], payload));
                Vue.set(state.all, payload.id, payload);
                // state.all[payload.id] = payload;
            },
            SELECT: (state, payload) => {
                state.selectedId = payload;
            },
            RESET: (state) => {
                Object.assign(state, createInitialState());
            },
            DELETE: (state, payload) => {
                delete state.all[payload];
                state.ids = state.ids.filter(id => id !== payload);
            },
            REPLACE: (state, payload) => {
                delete state.all[payload.oldId];
                state.ids = state.ids.filter(id => id !== payload.oldId);
                state.ids.push(payload.doc.id);
                Vue.set(state.all, payload.doc.id, payload.doc);
            },
            ADD: (state) => {
                state.ids.push('new');
                state.selectedId = 'new';
                Vue.set(state.all, 'new', {
                    name: '',
                    _id: 'new',
                    freq: 0,
                    selTz: 'MST',
                    tod: 1,
                    cc: '4111111111111111'
                });
            }
        }
    }
    return module;
}

export { createModule };
