<template>
  <v-container class="steps-container">
    <v-row class="animation-min-height">
      <v-col lg="3" cols="12" class="d-flex align-center step-card-container">
        <v-slide-x-transition>
          <v-card
            v-if="showStepOne"
            class="rounded-0 d-flex align-center justify-center step-card" 
            color="primary"
          >
          <span class="step-card__text">
            {{ $t('homePage.stepOne') }}
          </span>
          </v-card>
        </v-slide-x-transition>
      </v-col>
      <v-col lg="auto" cols="12" order="3" order-lg="2" class="d-flex align-center step-text-container">
        <v-slide-x-transition>
          <span v-if="showStepOne" class="step__text" v-html="$t('homePage.invite')"></span>
        </v-slide-x-transition>
      </v-col>
      <v-col lg="auto" cols="12" order="2" order-lg="3" class="d-flex align-center step-img-container">
      <v-slide-x-reverse-transition>
        <img v-if="showStepOne" :src="stepOne"/>
      </v-slide-x-reverse-transition>
      </v-col>
    </v-row>
    <v-row class="animation-min-height" v-intersect.once="onIntersectStepTwo">
      <v-col lg="3" cols="12" class="d-flex align-center step-card-container">
        <v-slide-x-transition>
          <v-card v-if="showStepTwo" class="rounded-0 d-flex align-center justify-center step-card" color="primary">
          <span dark class="step-card__text">
            {{ $t('homePage.stepTwo') }}
          </span>
          </v-card>
        </v-slide-x-transition>
      </v-col>
      <v-col lg="auto" cols="12" order="3" order-lg="2" class="d-flex align-center step-text-container">
        <v-slide-x-transition>
          <span v-if="showStepTwo" class="step__text" v-html="$t('homePage.receive')"></span>
        </v-slide-x-transition>
      </v-col>
      <v-col lg="auto" cols="12" order="2" order-lg="3" class="d-flex align-center step-img-container">
        <v-slide-x-reverse-transition>
          <img v-if="showStepTwo" :src="stepTwo"/>
        </v-slide-x-reverse-transition>
      </v-col>
    </v-row>
    <v-row class="animation-min-height" v-intersect.once="onIntersectStepThree">
      <v-col lg="3" cols="12" class="d-flex align-center step-card-container">
        <v-slide-x-transition>
          <v-card v-if="showStepThree" class="rounded-0 d-flex align-center justify-center step-card" color="primary">
          <span class="step-card__text">
            {{ $t('homePage.stepThree') }}
          </span>
          </v-card>
        </v-slide-x-transition>
      </v-col>
      <v-col lg="auto" cols="12" order="3" order-lg="2" class="d-flex align-center step-text-container">
        <v-slide-x-transition>
          <span v-if="showStepThree" class="step__text" v-html="$t('homePage.reply')"></span>
        </v-slide-x-transition>
      </v-col>
      <v-col lg="auto" cols="12" order="2" order-lg="3" class="d-flex align-center step-img-container">
        <v-slide-x-reverse-transition>
          <img v-if="showStepThree" :src="stepThree"/>
        </v-slide-x-reverse-transition>
      </v-col>
    </v-row>
    <v-row class="animation-min-height-step-four" v-intersect.once="onIntersectStepFour">
      <v-col lg="3" cols="12" class="d-flex align-center step-card-container">
        <v-slide-x-transition>
          <v-card v-if="showStepFour" class="rounded-0 d-flex align-center justify-center step-card" color="primary">
          <span class="step-card__text">
            {{ $t('homePage.stepFour') }}
          </span>
          </v-card>
        </v-slide-x-transition>
      </v-col>
      <v-col lg="auto" cols="12" order="3" order-lg="2" class="d-flex align-center step-text-container">
        <v-slide-x-transition>
          <span v-if="showStepFour" class="step__text step__text--bold">{{ $t('homePage.get') }}</span>
        </v-slide-x-transition>
      </v-col>
      <v-col lg="auto" cols="12" order="2" order-lg="3" class="d-flex align-center step-img-container">
        <v-slide-x-reverse-transition>
          <img v-if="showStepFour" :src="stepFour"/>
        </v-slide-x-reverse-transition>
      </v-col>
    </v-row>
    <v-row v-intersect.once="onIntersectRow">
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Steps',
    data() {
      return {
        showStepOne: false,
        showStepTwo: false,
        showStepThree: false,
        showStepFour: false,
      }
    },
    computed: {
      stepOne() {
        return require('@/assets/Step1.svg')
      },
      stepTwo() {
        return require('@/assets/Step2.svg')
      },
      stepThree() {
        return require('@/assets/Step3.svg')
      },
      stepFour() {
        return require('@/assets/Step4.svg')
      },
    },
    methods: {
      onIntersectStepTwo (entries) {
        let vm = this
        setTimeout(function() {
          vm.showStepOne = entries[0].isIntersecting;
        }, 500);
      },
      onIntersectStepThree (entries) {
        let vm = this
        setTimeout(function() {
          vm.showStepTwo = entries[0].isIntersecting;
        }, 550);
      },
      onIntersectStepFour (entries) {
        let vm = this
        setTimeout(function() {
          vm.showStepThree = entries[0].isIntersecting;
        }, 600);
      },
      onIntersectRow (entries) {
        let vm = this
        setTimeout(function() {
          vm.showStepFour = entries[0].isIntersecting;
        }, 650);
      },
    }
  }
</script>

<style scoped>
  .animation-min-height {
    min-height: 12rem;
  }
  .animation-min-height-step-four {
    min-height: 16rem;
  }
  .steps-container {
    margin-bottom: 5rem;
  }
  .step-card-container {
    justify-content: flex-end;
  }
  .step-card {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .step-card__text {
    font-weight: bold;
    font-size: 3rem;
    color: white;
    text-align: center;
    letter-spacing: 0.1em;
  }
  .step__text {
    font-size: 3.4rem;
  }
  .step__text--bold {
    font-weight: bold;
  }

@media only screen and (max-width: 1264px) {
  .steps-container {
    margin-bottom: 2.5rem;
  }
  .step-card-container {
    justify-content: center;
  }
  .step-text-container {
    justify-content: center;
  }
  .step-img-container {
    justify-content: center;
  }
} 
@media only screen and (min-width: 961px) and (max-width: 1264px) {
  .step__text {
    font-size: 2rem;
    text-align: center;
  }
  .step-card {
    width: 21rem;
  }
  img {
    width: 21rem;
    height: auto;
  }
}
@media only screen and (max-width: 960px) {
  .step-card {
    width: 8rem;
  }
  .step-card__text {
    font-size: 1.5rem;
  }
  .step__text {
    font-size: 1.5rem;
  }
  img {
    width: 9rem;
    height: auto;
  }
} 
</style>