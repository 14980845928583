<template>
  <v-container>
    <h2 class="header header-span mb-8">Privacy Policy</h2>
    <p class="mb-13">
      Your privacy matters to us!
    </p>
    <p class="mb-13">
      Jambooks does not sell, rent, license, or distribute any of the information provided to the service for any reason. This includes personal information, telephone numbers, email addresses, names, payment information, or anything else related to the service. We never sell anything you provide us, ever.
    </p>

    <p class="mb-13">
      The Jambooks website uses 256-bit SSL encryption, which means your interaction with Jambooks is private and secure.
    </p>
    <p class="mb-13">
      Jambooks has a strict confidentiality policy within our organization regarding the information you share through Jambooks. All Jambooks staff members with access to your information, including access to your book and the responses of you and your Jammers, have passed a criminal background check and sex-offender registry check.
    </p>
    <p class="mb-13">
      Please remember that no method or transmission of information over the Internet or through text is always 100% secure. That said, we use every possible, commercially acceptable means to ensure your privacy and protect your personal information.
    </p>

    <p class="mb-13 banner__text header-span">
      If you have questions or concerns about privacy in any way, please contact support@jambooks.co.
    </p>

  </v-container>
</template>

<script>
  export default {
    name: 'PrivacyPolicy',
    data() {
      return {
        name: ''
      }
    }
  }
</script>
<style scoped>
  .header {
    color: var(--v-primary-base);
    text-align: center;
    line-height: 100%;
  }

  .banner__text {
    font-size: 2.4rem;
    color: white;
    text-align: center;
    background-color: var(--v-text-base);
  }
</style>
