export const name = 'type';
import Vue from 'vue';

export const getterNames = {
    getTypes: `${name}/getTypes`,
    isTypeLoaded: `${name}/isTypeLoaded`,
    getByCat: `${name}/getByCat`,
    getById: `${name}/getById`
}

export const mutationNames = {
    SAVE_TYPE: `${name}/SAVE_TYPES`
}

export const createInitialState = () => ({
    all: {}, // all.id = {}
    ids: {}, // ids.lang.[] = ''
    loaded: {}, // loaded.lang = boolean
    selectedId: null
})

function createModule() {
    return {
        namespaced: true,
        state: () => createInitialState(),
        getters: {
            getTypes: (state, getters, rootState) => {
                if (state.ids[rootState.lang]) {
                    return state.ids[rootState.lang].map(typeId => state.all[typeId]);
                } else {
                    return {};
                }
            },
            isTypeLoaded: (state, getters, rootState) => {
                return (state.loaded[rootState.lang] !== undefined);
            },
            getByCat: (state, getters, rootState) => {
                let ret = {};
                if (state.ids[rootState.lang]) {
                    state.ids[rootState.lang].forEach(typeId => {
                        if (!ret[state.all[typeId].cat]) {
                            ret[state.all[typeId].cat] = [];
                        }
                        ret[state.all[typeId].cat].push(state.all[typeId]);
                    });
                }
                return ret;
            },
            getById: (state) => (id) => {
                return state.all[id];
            }
        },
        mutations: {
            SAVE_TYPES: (state, payload) => {
                Vue.set(state, 'all', payload.all);
                Vue.set(state.ids, payload.lang, payload.ids);
                Vue.set(state.loaded, payload.lang, true);
            }
        }
    }
}

export { createModule };
